import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import formsSlicer from './slice/formsSlicer';
import pageSlice from './slice/pageSlice';
import salespersonSlice from './slice/salespersonSlice';
import userSlice from './slice/userSlice';




const reducers = combineReducers({
    user:userSlice,
    salesPerson:salespersonSlice,
    page:pageSlice,
    form:formsSlicer,

})

export default configureStore({
    reducer: reducers
})
