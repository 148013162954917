import React, { useEffect, useState } from "react";
import "../../assets/css/profile.css";
// import userProfileLayout from "../../hoc/userProfileLayout";

import { NavLink } from "react-router-dom";
import ChangePasswordPage from "./ChangePasswordPage";
import UserPreferencesPage from "./UserPreferencesPage";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../config";
const ProfilePage = () => {
  const [Profile, setProfile] = useState();
  const { user } = useSelector((state) => state.user);
  console.log(user, "chek user");
  const [Name, setName] = useState(user?.Name);
  const [Email, setEmail] = useState(user?.Email);
  const [Password] = useState(user?.Password);
  const [ContactNumber, setContactNumber] = useState(user?.ContactNumber);
  const [checkPassword, setCheckPassword] = useState(false);
  const [IsLoading, setIsLoading] = useState();

  useEffect(() => {
    setProfile("PROFILE");
    
  }, []);
  const updatedProfile = async (e) => {
   
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.put(`/api/user/${user._id}`, {
        Name,
        Email,
        ContactNumber,
      });
      console.log("data", "check data");
      if (data.success) {
        alert(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert(error.response.data.message);
      console.log(error, "in updatedPassword");
    }
  };
  return (
    <div className="container">
      <div className="row profile">
        <div className="col-md-3">
          <div className="profile-sidebar">
            <div className="my-3 p-3 bg-body rounded shadow-sm">
              {/* <!-- SIDEBAR USERPIC --> */}
              <div className="profile-userpic">
                <img
                  src="https://via.placeholder.com/150"
                  className="img-responsive profile-img-center"
                  alt=""
                />
              </div>
              {/* <!-- END SIDEBAR USERPIC -->
                            <!-- SIDEBAR USER TITLE --> */}
              <div className="profile-usertitle yellow">
                <div className="profile-usertitle-name">{user?.Name}</div>
                <div className="profile-usertitle-job ">
                  {user?.App?.AppName}
                </div>
              </div>

              <hr />
              <div>
                <div className="bd-example">
                  <div className="list-group">
                    <NavLink
                      onClick={() => setProfile("PROFILE")}
                      className="list-group-item list-group-item-action yellow"
                    >
                      Personal Info
                    </NavLink>
                    <NavLink
                      onClick={() => setProfile("PASSWORD")}
                      className={`list-group-item list-group-item-action}`}
                    >
                      Change Password
                    </NavLink>
                    <NavLink
                      onClick={() => setProfile("preferences")}
                      className={`list-group-item list-group-item-action`}
                    >
                      Preferences
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Profile === "PROFILE" && (
          <>
            <div className="col-md-9">
              <div className="profile-content">
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                  <h6 className="border-bottom pb-2 mb-0 mb-3 yellow">
                    Personal Info
                  </h6>
                  {/* <form> */}
                    <div className="row">
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Name
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                            aria-label="Recipient's name"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            <i className="fa fa-user"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email Address"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            @
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Password
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type={checkPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            aria-label="Recipient's Password"
                            value={Password}
                            // onChange={(e)=>setPassword(e.target.value)}
                            disabled
                            aria-describedby="basic-addon2"
                          />
                          <span
                            onClick={() => setCheckPassword(!checkPassword)}
                            className="input-group-text"
                            id="basic-addon2"
                          >
                            {!checkPassword ? (
                              <i class="fa-solid fa-lock"></i>
                            ) : (
                              <i class="fa-sharp fa-solid fa-lock-open"></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          App Id
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="App Id"
                            disabled
                            value={user?.App?.AppID}
                            aria-label="Recipient's  App Id"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            <i class="fa-solid fa-key"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Contact Number
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contact Number"
                            value={ContactNumber}
                            onChange={(e) => setContactNumber(e.target.value)}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            <i className="fa fa-mobile"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    {!IsLoading ? (
                      <button
                        className="btn bgYellow"
                        onClick={(e) => updatedProfile(e)}
                      >
                        Update Profile
                      </button>
                    ) : (
                      <button
                        className="btn bgYellow"
                        
                      >
                       Loading...
                      </button>
                    )}
                  {/* </form> */}
                </div>
              </div>
            </div>
          </>
        )}
        {Profile === "PASSWORD" && (
          <>
            <ChangePasswordPage user={user} />
          </>
        )}
        {Profile === "preferences" && (
          <>
            <UserPreferencesPage user={user} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
