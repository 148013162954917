import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { axiosInstance } from '../../config';
import Sidebar from "../sidebar/Sidebar";

const EditService = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [ServiceName, setServiceName] = useState();



  useEffect(() => {
    getservice()
  }, [])

  const getservice = async() =>{
    try {
      const {data} = await axiosInstance.get(`/api/services?_id=${id}`);
      setServiceName(data.data[0].serviceName)
    } catch (error) {
      console.log("error",error)
    }
  }

  const updateService = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(`/api/services/${id}`, {
        "serviceName":ServiceName
      });
      if (data.success) {
        navigate("/client/services")
        alert(data.message);
      }
    } catch (error) {
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

  
  return (
    <>
     <Sidebar />
     <div className="p-1 my-container active-cont">
     <Navbar  name={"Edit Service"}/>
        <hr className='hrTag' />
        {/* <h3 className="text-dark p-3"> Service </h3> */}
        <div className="row mx-2 ">
          

          <form>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Service Name</label>
              <input
                className="form-control "
                type="text"
                value={ServiceName}
                onChange={(e) => setServiceName(e.target.value)}
                placeholder="Service Name"
                required
              />
            </div>
           
          </form>
          <div className="col-md-8" style={{ padding: "5px" }}>
            <div className="d-flex flex-row">
              <button className="btn btn-success" onClick={updateService}>
                Update 
              </button>
            </div>
          </div>
        </div>
      </div>
    
    </>
  )
}

export default EditService