import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getpages,
  pagedatafailure,
  pagedatasuccess,
} from "../../redux/slice/pageSlice";
import {
  FormsDataFailure,
  FormsDataSuccess,
  getForms,
} from "../../redux/slice/formsSlicer";
import Navbar from "../../components/navbar/Navbar";
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
const AssignLeads = () => {
  const { user } = useSelector((state) => state.user);
  const { page } = useSelector((state) => state.page);
  const { forms } = useSelector((state) => state.form);

  const dispatch = useDispatch();

  const [Leads, setLeads] = useState();
  const [leadsKey, setLeadsKey] = useState([]);
  const [serviceName, setServiceName] = useState();
  const [selectedPage, setSelectedPage] = useState();
  const [allPages, setAllPages] = useState();
  const [allForm, setAllForm] = useState();
  const [SalesPersonData, setSalesPersonData] = useState();
  const [selectedSalesPerson, setSelectedSalesPerson] = useState();
  const [selectedFormId, setSelectedFormId] = useState();
  // const [Services, setServices] = useState();
  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    getLeads();
  }, []);

  const getAllPages = async () => {
    if (page.length !== 0) {
      return;
    }
    console.log("ddd2", page);

    dispatch(getpages());
    try {
      const {
        data: { data },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${user?.App?.AccountId}/accounts?access_token=${user?.App?.PermanentAccessToken}`
      );
      dispatch(pagedatasuccess(data));
      setAllPages(data);
      console.log(data, "check data");
    } catch (error) {
      dispatch(pagedatafailure());
      console.log(error, "error");
      return false;
    }
  };

  const getAllForm = async (e) => {
    setSelectedPage(JSON.parse(e.target.value));
    const pageData = JSON.parse(e.target.value); // page object

    try {
      dispatch(getForms());
      if (forms[pageData.id]) {
        setAllForm(forms[pageData.id]);
        return false;
      }
      console.log("----------------- API FORM CALLED ------------");
      const {
        data: { data: formData },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${pageData.id}/leadgen_forms?access_token=${pageData.access_token}`
      );
      dispatch(
        FormsDataSuccess({
          ...forms,
          [pageData.id]: formData,
        })
      );
      console.log("fffff", forms);
      setAllForm(formData);
    } catch (error) {
      dispatch(FormsDataFailure(error));
    }
  };

  const getLeads = async (e) => {
    const formData = JSON.parse(e.target.value);
    setSelectedFormId(JSON.parse(e.target.value));
    setLeads([]);
    setLeadsKey([]);
    setIsDisable(false)
    try {
      const {
        data: { isExists },
      } = await axiosInstance.post(`/api/page/getLeadsData`, {
        key: "isAssigned",
        value: false,
        formId: formData.id,
      }); //545752797430909 formData.id
      if (isExists.leads.length === 0) {
        setLeads([]);
        setLeadsKey([]);
        
      } else {
        console.log("running");
        const leadsKey = Object.keys(isExists.leads[0]);
        console.log(isExists, "isExists check should");
        setLeads(isExists.leads);
        if (isExists.Services) {
          setIsDisable(true)
          getSalesPersonBycalling(isExists.Services)
        }
        setLeadsKey(
          leadsKey.filter(
            (e) =>
              e !== "LeadType" &&
              e !== "isAssigned" &&
              e !== "xyz" &&
              e !== "salesPerson" &&
              e !== "LeadAssignedTime" &&
              e !== "newDate" &&
              e !== "Action" &&
              e !== "leadgen_id" &&
              e !== "Lead_Created_Date"
          )
        );
      }
    } catch (error) {
      setLeads([]);
      setLeadsKey([]);
      console.log("ERROR RUNNING");
      console.log(error, "isExists ERROR RUNNING");
    }
  };

  const getSalesPerson = async (e) => {
    try {
     
        console.log(JSON.parse(e.target.value), "check event");
        const services = JSON.parse(e.target.value);
        const { data } = await axiosInstance.get(
          `/api/salesPerson?Service=${services._id}&User=${user._id}`
          );
          setSalesPersonData(data.data);
          console.log(data.data, "check sales person");
    
     
    } catch (error) {}
  };

  const getSalesPersonBycalling = async (servicesId) => {
    console.log(servicesId,"servicesId 158");
    try {
     
       
        const { data } = await axiosInstance.get(
          `/api/salesPerson?Service=${servicesId}&User=${user._id}`
          );
          setSalesPersonData(data.data);
          console.log(data.data, "check sales person");
    
     
    } catch (error) {}
  };
  const getServiceName = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/services?User=${user._id}`
      );
      console.log(data.data, "abcd");

      setServiceName(data.data);
    } catch (error) {
      console.log("error", error);
      // alert(error.response.data.message);
    }
  };
  const ConvertDate = (time) => {
    const date = new Date(time).toString().slice(0, 25);
    console.log(date, "date");
    return <td>{date}</td>;
  };
  const assignLeadsToSalesPerson = async (lead) => {
    console.log("assignLeads", lead);
    console.log("SalesPersonData check", selectedSalesPerson);
    console.log("selectedFormId", selectedFormId);

    try {
      if (!selectedSalesPerson) {
        return alert("Select a Sales Person");
      }

      const { data } = await axiosInstance.put(`/api/page/setAssignedLead`, {
        form_id: selectedFormId.id,
        leadgen_id: lead.leadgen_id,
        salesPerson: {
          id: selectedSalesPerson._id,
          name: selectedSalesPerson.Name,
        },
      });
      console.log(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!allPages) ConvertDate();
    getServiceName();
  }, []);

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <>
      <Sidebar  />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar name={"Unassign Lead"} />
        <hr className="hrTag" />
        {/* <!--End Top Nav --> */}
        <div className="row ">
          <div className="col-md-4 select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Page
            </label>
            <select onChange={(e) => getAllForm(e)}>
              <option selected hidden>
                select page
              </option>
              {page &&
                page.map((page, i) => (
                  <option key={i} value={JSON.stringify(page)}>
                    {page?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-5  select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Form
            </label>
            <select onChange={(e) => getLeads(e)}>
              <option selected hidden>
                select Form
              </option>
              {!allForm?.length && <option>no form</option>}
              {allForm &&
                allForm.map((form, i) => (
                  <option key={i} value={JSON.stringify(form)}>
                    {form.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        <div className="row mt-3 ">
          <div className="col-md-4  select  mx-5">
            <label className="text-white align-self-center m-3">Service</label>
            <select disabled={isDisable}  onChange={(e) => getSalesPerson(e)}>
              <option selected hidden>
                Select Service
              </option>
              {!serviceName?.length && <option>no service</option>}
              {serviceName &&
                serviceName.map((service, i) => (
                  <option key={i} value={JSON.stringify(service)}>
                    {service.serviceName}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-5   select  mx-5">
            <label className="text-white align-self-center m-3">
              Sales Person
            </label>
            <select
              onChange={(e) =>
                setSelectedSalesPerson(JSON.parse(e.target.value))
              }
            >
              <option selected hidden>
                Select Sales Person
              </option>
              {!SalesPersonData?.length && <option>no sales person</option>}
              {SalesPersonData &&
                SalesPersonData.map((PersonData, i) => (
                  <option key={i} value={JSON.stringify(PersonData)}>
                    {PersonData?.Name}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {Leads && Leads.length !== 0 && (
          <div class="table-responsive">
            <table class="table table-striped table-dark  table-bordered  overflow-x mt-3">
              <thead>
                <tr>
                  <th scope="col">S/No</th>
                  {leadsKey &&
                    leadsKey.map((data) => <th scope="col">{data}</th>)}
                  {/* <th scope="col">Action</th> */}
                  <th scope="col">Assign_leads</th>
                </tr>
              </thead>
              <tbody>
                {Leads &&
                  Leads.map((lead, index) => (
                    <tr key={index}>
                      <th scope="row">{index+1}</th>
                      {leadsKey.map((data) => (
                        <>
                          {data === "date" ? (
                            ConvertDate(lead[data])
                          ) : (
                            <td>{lead[data]}</td>
                          )}
                        </>
                      ))}
                      {/* <td>
                        <div
                          class=""
                          style={{
                            width: "6em",
                          }}
                        >
                          {lead?.LeadType}
                        </div>
                      </td> */}
                      <td>
                      <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="icon-button"
                                onClick={() => assignLeadsToSalesPerson(lead)}
                              >
                                <CoPresentOutlinedIcon />
                              </button>
                            </div>
                        
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
        {Leads && Leads.length === 0 && (
          <>
            <div className="m-5">
              <h1 className="text-white text-center ">
                {" "}
                No New Leads To Assign
              </h1>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AssignLeads;
