import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import { axiosInstance } from '../../config';
import Sidebar from '../sidebar/Sidebar';


const AddService = () => {

    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const [serviceName, setServiceName] = useState();

   

    const createUser = async (e) => {
        e.preventDefault();
        try {
            if (
                !serviceName
            ) {
                return alert("Service Name Required");
            }
            const { data } = await axiosInstance.post(`/api/Services`, {
                serviceName,
                User:user._id
            });
            if (data.success) {
                navigate("/client/services")
                alert(data.message);
            }
        } catch (error) {
            console.log(error, "error creating data");
        }
    };

    return (
        <>
            <Sidebar />
            <div className="p-1 my-container active-cont">
                {/* <!-- Top Nav --> */}
                <Navbar  name={"Add Service"}/>
        <hr className='hrTag' />
                {/* <!--End Top Nav --> */}
               
                <div className="row mx-2 ">
                    {/* <div className="toogler">
            <h3>Create Team</h3>
          </div> */}

                    <form
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                        }}
                        onSubmit={createUser}
                    >
                        <div className="col-md-8 align-items-center">
                            <label className="text-white mt-2 mb-2">Service Name</label>
                            <input
                                className="form-control"
                                type="text"
                                value={serviceName}
                                onChange={(e) => setServiceName(e.target.value)}
                                placeholder="Service Name"
                                required
                            />
                        </div>


                        <div className="col-md-8" style={{ padding: "5px" }}>
                            <div className="d-flex flex-row mt-2 mb-2 ">
                                <button className="btn bgYellow">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AddService