import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
    name: 'page',
    initialState:{
        page:[],
        loading:false,
        error:false,
        errormessage:""
    },
    reducers:{
        getpages:(state,action)=>{
            state.loading = true;
            state.error = false
        },
        pagedatasuccess:(state,action)=>{
            state.loading = false;
            state.page = action.payload
        },
        pagedatafailure:(state,action)=>{
            state.loading = false;
            state.error = true;
            state.errormessage = action.payload;
        },
        reseterror:(state,action)=>{
            state.page = [];
            state.loading = false;
            state.error = false;
        }
    }
})


export const {getpages,pagedatasuccess,pagedatafailure,reseterror} = pageSlice.actions;
export default pageSlice.reducer;
