import React from 'react';
import { Link } from 'react-router-dom';
import "./sidebar.css"
const Sidebar = () => {
    
    return (
        <>
            <div style={{height:"100vh", overflowY:"scroll"}} className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
                <ul className="nav flex-column text-white w-100 ">
                    <a href=" " className="nav-link h6 text-white my-2">
                       DIGI SIDEKICK DASHBOARD
                    </a>
                    < hr className='hrTag'/>
                    <Link to="/admin/app" className="nav-link">
                        <i className="bx bx-body text-white"></i>
                        <span className="mx-2 text-white">Application</span>
                    </Link>
                    <Link to="/admin/user" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">CLIENT</span>
                    </Link>
                    <Link className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">LOGOUT</span>
                    </Link>
               
                   
               
                  
                </ul>

            </div>


        </>
    )
}

export default Sidebar