import React from "react";
import "./Navbar.scss";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useSelector } from "react-redux";
const Navbar = ({ name ,isSalesPerson,isAdmin}) => {

    const { user } = useSelector((state) => state.user);
  const togglehandle = (e) => {
    e.persist();

    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };
 
  return (
    <div className="navbar" style={{
      height:"4.3rem",
    }}>
      <div className="navbar-cont">
        <div className="Heading">
          {/* <h3>{name} 💻 📱</h3> */}
        </div>

        <div className="nav-icons">
        
          <div className="PageBox">

            {
              isAdmin ? "AARJAV JAIN" : isSalesPerson ?"MARKETING SERVICES LEAD": user?.selectedPage?.name 
            }
              {/* {  isAdmin && isSalesPerson? "MARKETING SERVICES LEAD": user?.selectedPage?.name   }
              {  isAdmin && "AARJAV JAIN"   } */}
          </div>
          <div>
            <DehazeIcon onClick={togglehandle} className="nav-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
