import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import Navbar from "../../components/navbar/Navbar";

const AllApplication = () => {
  const [App, setApp] = useState();
  const [del, setDel] = useState(false);

  useEffect(() => {
    getApp();
  }, [del]);

  const getApp = async () => {
    try {
      const { data } = await axiosInstance.get("/api/app");
      setApp(data.data);
    } catch (error) {
      alert(error.response.data.message);
    }
  };


  const deleteContext = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    try {
      if (answer) {
        const { data } = await axiosInstance.delete(`/api/app/${id}`);
        if (data.success) {
          alert(data.message);
        }
      }
      setDel(!del);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true} />
        < hr className='hrTag'/>

        <div className="row ">
          <div className="col-md-9"></div>
          <div className="col-md-3 btn btn-secondary  mx-5">
            <Link to="/admin/CreateApp" className="text-white nav-link">
              CREATE APP
            </Link>
          </div>
        </div>

        <div className="row mt-4">
          <div className=" text-white col-md-2"> COMPANY NAME</div>
          <div className=" text-white col-md-2"> AppId</div>
          <div className="text-white col-md-4"> App Secret</div>
         
         

          <div className="text-white col-md-2 btn btn-secondary">
            EXTRA OPTIONS
          </div>
        </div>

        {!App ? (
          <div
            className="row d-flex justify-content-center position-relative top-50 mt-5 "
            style={{ height: "100vh" }}
          >
            <div className="col-md-8 text center position-absolute top-40 start-50">
              <div class="spinner-border text-white " role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
        ) : App.length === 0 ? (
          <>
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-md-8 text-center">
                <h4 className="text-white">No Contest Available</h4>
              </div>
            </div>
          </>
        ) : (
          App &&
          App.map((c) => (
            <>
              <div className="row " key={c?._id}>
              <div className="col-md-2 text-white">{c?.AppName} </div>
                <Link href={`/admin/subscribe/${c?.AppID}`} className="col-md-2 text-white">{c?.AppID} </Link>
                <div className="col-md-4 text-white" >{c?.AppSecret}</div>
                

                <div className="col-md-2">
                  <div className="row">
                    <div className="col-md-4">
                      <Link className="col-md-1" to={`/admin/editApp/${c._id}`}>
                        <BsFillEyeFill size={25} color="blue" />{" "}
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <AiFillDelete
                        color="white"
                        size={25}
                        onClick={() => deleteContext(c._id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        )}
      </div>
    </>
  );
};

export default AllApplication;
