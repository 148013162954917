import React, { useState, useEffect } from "react";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import Navbar from "../../components/navbar/Navbar";
import { useSelector } from "react-redux";

const SalesPersonProfile = () => {
  const { salesPerson } = useSelector((state) => state.salesPerson);
  const [checkPassword, setCheckPassword] = useState(false);

useEffect(()=>{
  console.log(salesPerson,"salesPerson salesPerson");
})
  return (
    <>
      <Sidebar  />

      <div className="p-1 my-container active-cont overflow-hidden">
      <Navbar name={"Settings"} isSalesPerson={true}/>
        <hr className='hrTag' />
        {/* <!-- Top Nav --> */}
        
        <div className="container">
      <div className="row profile">
      
     
          <>
            <div className="col-md-9">
              <div className="profile-content">
                <div className="my-3 p-3 bg-body rounded shadow-sm">
                  <h6 className="border-bottom pb-2 mb-0 mb-3 yellow">
                    Personal Info
                  </h6>
                  {/* <form> */}
                    <div className="row">
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Name
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={salesPerson.Name}
                            // onChange={(e) => setName(e.target.value)}
                            aria-label="Recipient's name"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            <i className="fa fa-user"></i>
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={salesPerson.Email}
                            // onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email Address"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            @
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                    <div className="col">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Password
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type={checkPassword ? "text" : "password"}
                            className="form-control"
                            placeholder="Password"
                            aria-label="Recipient's Password"
                            value={salesPerson.Password}
                            // onChange={(e)=>setPassword(e.target.value)}
                            disabled
                            aria-describedby="basic-addon2"
                          />
                          <span
                            onClick={() => setCheckPassword(!checkPassword)}
                            className="input-group-text"
                            id="basic-addon2"
                          >
                            {!checkPassword ? (
                              <i class="fa-solid fa-lock"></i>
                            ) : (
                              <i class="fa-sharp fa-solid fa-lock-open"></i>
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Contact Number
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Contact Number"
                            value={salesPerson.Number}
                            // onChange={(e) => setContactNumber(e.target.value)}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                          />
                          <span className="input-group-text" id="basic-addon2">
                            <i className="fa fa-mobile"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                  
                  {/* </form> */}
                </div>
              </div>
            </div>
          </>
       
       
      </div>
    </div>

      </div>
    </>
  );
};

export default SalesPersonProfile;
