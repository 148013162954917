import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";
import { Link } from "react-router-dom";
// import "./leads.css";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getpages,
  pagedatafailure,
  pagedatasuccess,
} from "../../redux/slice/pageSlice";
import "../sidebar/sidebar.css";
import {
  FormsDataFailure,
  FormsDataSuccess,
  getForms,
} from "../../redux/slice/formsSlicer";
import Navbar from "../../components/navbar/Navbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import TablePagination from "@mui/material/TablePagination";
const Leads = () => {
  const { user } = useSelector((state) => state.user);
  const { page } = useSelector((state) => state.page);
  const { forms } = useSelector((state) => state.form);

  const dispatch = useDispatch();

  const [Leads, setLeads] = useState();
  const [leadsKey, setLeadsKey] = useState([]);
  const [fromTime, setFromTime] = useState(dayjs());
  const [toTime, setToTime] = useState(dayjs());
  const [selectedPage, setSelectedPage] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [allPages, setAllPages] = useState();
  const [allForm, setAllForm] = useState();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [LeadType, setLeadType] = useState("ALL LEAD");
  const [pagination, setPagination] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isCalled, setIsCalled] = useState(false);

  useEffect(() => {
    getLeads();
  }, []);

  const getAllPages = async () => {
    if (page.length !== 0) {
      return;
    }
    console.log("ddd2", page);

    dispatch(getpages());
    try {
      const {
        data: { data },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${user?.App?.AccountId}/accounts?access_token=${user?.App?.PermanentAccessToken}`
      );
      dispatch(pagedatasuccess(data));
      setAllPages(data);
      console.log(data, "check data");
    } catch (error) {
      dispatch(pagedatafailure());
      console.log(error, "error");
      return false;
    }
  };

  const getAllForm = async (e) => {
    setSelectedPage(JSON.parse(e.target.value));
    const pageData = JSON.parse(e.target.value); // page object

    try {
      dispatch(getForms());
      if (forms[pageData.id]) {
        setAllForm(forms[pageData.id]);
        return false;
      }
      console.log("----------------- API FORM CALLED ------------");
      const {
        data: { data: formData },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${pageData.id}/leadgen_forms?access_token=${pageData.access_token}`
      );
      dispatch(
        FormsDataSuccess({
          ...forms,
          [pageData.id]: formData,
        })
      );
      console.log("fffff", forms);
      setAllForm(formData);
    } catch (error) {
      dispatch(FormsDataFailure(error));
    }
  };

  const getLeads = async (e) => {
    console.log("called in afe");
    const formData = e ? JSON.parse(e.target.value) : selectedForm;
    if (e) {
      setSelectedForm(formData);
    }
    try {
      const {
        data: { isExists },
      } = await axiosInstance.post("/api/page/findUserPages", {
        page_id: selectedPage.id,
        form_id: formData.id,
      });
      console.log(isExists, "isExists");
      if (isExists.length === 0) {
        setLeads([]);
        setLeadsKey([]);
      } else {
        const leadsKey = Object.keys(isExists.leads[0]);
        console.log("running", isExists.leads, "leadsKey");
        setLeads(isExists.leads);
        setLeadsKey(
          leadsKey.filter(
            (e) =>
              e !== "isAssigned" &&
              e !== "leadgen_id" &&
              e !== "date" &&
              e !== "LeadAssignedTime" &&
              e !== "salesPerson" &&
              e !== "LeadType" &&
              e !== "Lead_Created_Date" &&
              e !== "newDate" &&
              e !== "comment"
          )
        );
      }
    } catch (error) {
      setLeads([]);
      setLeadsKey([]);
      console.log("ERROR RUNNING");
      console.log(error, "isExists");
    }
  };

  const setLeadTypeHandler = async (e, lead) => {
    try {
      console.log("Running lead ", e.target.value);
      const {
        data: { data },
      } = await axiosInstance.put("/api/page/setLeadType", {
        form_id: selectedForm.id,
        leadgen_id: lead.leadgen_id,
        LeadType: e.target.value,
      });
      console.log(data, "data selectedFormId");
      if (data.matchedCount !== 1) {
        alert("lead not update ");
      }
      console.log("running 1");
      isCalled ? getLeadsByTimeToFrom():getLeads()
      
      console.log("running 2");
    } catch (error) {
      alert("lead not update");
    }
  };

  const ConvertDate = (time) => {
    const date = new Date(time).toString().slice(0, 25);
    console.log(date, "date");
    return <td>{date}</td>;
  };

  // useEffect(() => {
  //   if (!allPages) ConvertDate();
  // }, []);

  useEffect(() => {
    getAllPages();
  }, []);

  const query = (lead, e) => {
    e.preventDefault();
    console.log(lead, "leads called");
    setModalData(lead);
    // setemployeedetails(employeedetails)
    setModal(true);
  };

  const getLeadsByTimeToFrom = async () => {
    // console.log(user.AppId,"user.AppId",selectedForm.id,"formId",fromTime.$d,toTime.$d);
    console.log(selectedForm, "selectedForm");
    if (!selectedForm?.id) {
      return alert("Please select a form");
    }
    try {
      const {
        data: { data },
      } = await axiosInstance.post("/api/page/getLeadsByTime", {
        appId: user.AppId,
        formId: selectedForm.id,
        fromTime: fromTime.$d,
        toTime: toTime.$d,
      });
      console.log(data[0]);
      if (data[0].leads.length === 0) {
        setLeads([]);
        setLeadsKey([]);
        setIsCalled(false)
      } else {
        const leadsKey = Object.keys(data[0].leads[0]);
        console.log("running", data[0].leads, "leadsKey check");
        setLeads(data[0].leads);

        setLeadsKey(
          leadsKey.filter(
            (e) =>
              e !== "isAssigned" &&
              e !== "leadgen_id" &&
              e !== "date" &&
              e !== "LeadAssignedTime" &&
              e !== "salesPerson" &&
              e !== "LeadType" &&
              e !== "Lead_Created_Date" &&
              e !== "newDate" &&
              e !== "comment"
          )
        );
        setIsCalled(true)
      }
    } catch (error) {}
  };
  const resetAll = () => {
    if (selectedForm?.id) {
      console.log(" form selected", selectedForm, "sjjsjsj");
      setLeadType("ALL LEAD");
      setIsCalled(false)
      getLeads();

    } else {
      console.log("not form selected");
    }
  };
  const handleChangePage = (event, newPage) => {
    setPagination(newPage);
    console.log(newPage,"newPage");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPagination(0);
    console.log(parseInt(event.target.value, 10),"handleChangeRowsPerPage");
  };
  

  return (
    <>
      {modal && (
        <>
          <div
            class="modal fade d-block mt-5  modal-lg"
            style={{ opacity: "1" }}
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" style={{ margin: "3.75rem auto" }}>
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    View Employee Stats
                  </h5>
                </div>
                <table class="table responsive" id="table-to-xls">
                  <thead>
                    <tr>
                      <th scope="col">leadgen_id</th>
                      <th scope="col">Lead_Created_Date</th>
                      <th scope="col">Lead_Assigned_Time</th>
                      <th scope="col">Lead_Assigned_To</th>
                      <th scope="col">comment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{modalData?.leadgen_id}</td>
                      <td>{ConvertDate(modalData?.Lead_Created_Date)}</td>
                      <td>{ConvertDate(modalData?.LeadAssignedTime)}</td>
                      {/* <td>{ConvertDate(modalData?.Lead_Created_Date)}</td> */}
                      <td>{modalData?.salesPerson?.name}</td>
                      <td>{modalData?.comment}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="modal-body py-5">
                  {/* <div class="modal-footer"> */}
                  <button
                    type="button"
                    class="btn bgYellow close modal-footer mt-5"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <Sidebar name={"Leads"} />
      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar name={"Leads"} />
        <hr className="hrTag" />
        <div class="transfer-section-header mt-3 mb-3">
          {/* <h5
            className="text-white"
            style={{
              whiteSpace: "nowrap",
              marginRight: "1rem",
            }}
          >
            Latest Filters
          </h5> */}
          <div class="filter-options text-white">
            <div className="d-flex">
              {" "}
              <div className="col-md-2 select  ">
                {/* <label className="text-white align-self-center m-3">
              Select Page
            </label> */}
                <select onChange={(e) => getAllForm(e)}>
                  <option selected hidden>
                    Select page
                  </option>
                  {page &&
                    page.map((page, i) => (
                      <option key={i} value={JSON.stringify(page)}>
                        {page?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3 select">
                {/* <label className="text-white align-self-center m-3">
              Select Form
            </label> */}
                <select onChange={(e) => getLeads(e)}>
                  <option selected hidden>
                    Select Form
                  </option>
                  {!allForm?.length && <option>no form</option>}
                  {allForm &&
                    allForm.map((form, i) => (
                      <option key={i} value={JSON.stringify(form)}>
                        {form.name}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="col-md-1"></div> */}
              <div className="col-md-2 AllFilters">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    // label="DateTimePicker"
                    disableFuture={true}
                    value={fromTime}
                    className="text-white"
                    onChange={(newValue) => {
                      setFromTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
              <div>
                <p
                  className="text-center"
                  style={{
                    margin: "0.6rem",
                  }}
                >
                  To
                </p>
              </div>
              <div className="col-md-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
                    // label="DateTimePicker"
                    disableFuture={true}
                    value={toTime}
                    className="text-white"
                    onChange={(newValue) => {
                      setToTime(newValue);
                    }}
                  />
                </LocalizationProvider>
              </div>
              <IconButton
                onClick={() => getLeadsByTimeToFrom()}
                className="m-1 text-white"
              >
                <SendIcon />
              </IconButton>
              <div className="col-md-2 d-flex p-3">
                <button class="icon-button">
                  <div class="dropdown">
                    <FilterListOutlinedIcon
                      role="button"
                      // className="dropdown-toggle dropdown-toggle-split"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-reference="parent"
                    />

                    <ul class="dropdown-menu">
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("NEW LEAD")}
                      >
                        NEW LEAD
                      </li>
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("NOT QUALIFIED")}
                      >
                        NOT QUALIFIED
                      </li>
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("QUALIFIED")}
                      >
                        QUALIFIED
                      </li>
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("HOT LEAD")}
                      >
                        HOT LEAD
                      </li>
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("NOT PICKUP")}
                      >
                        NOT PICKUP
                      </li>
                      <li
                        class="dropdown-item"
                        onClick={() => setLeadType("CONVERSION LEAD")}
                      >
                        CONVERSION LEAD
                      </li>
                    </ul>
                  </div>
                </button>
                <button class="icon-button mx-2" onClick={() => resetAll()}>
                  <i class="fa-solid fa-eraser"></i>
                </button>
              </div>
            </div>
            <div className="d-flex"></div>
          </div>
        </div>

        {/* <!--End Top Nav --> */}
        {/* <div className="row mt-3 mb-3"></div>
        <hr className="hrTag" /> */}

        {Leads && Leads.length !== 0 && (
          <div class="table-responsive">
            <table
              id="example"
              class="table table-striped table-dark  table-bordered  overflow-x mt-3"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                  {leadsKey &&
                    leadsKey.map((data) => <th scope="col">{data}</th>)}
                  <th
                    scope="col"
                    style={{
                      display: "flex",
                      alignItem: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Sales_Type{" "}
                    {/* <div class="dropdown">
                      <BsThreeDotsVertical
                        role="button"
                        // className="dropdown-toggle dropdown-toggle-split"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-reference="parent"
                      />

                      <ul class="dropdown-menu">
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("ALL LEAD")}
                        >
                          ALL LEAD
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("NEW LEAD")}
                        >
                          NEW LEAD
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("NOT QUALIFIED")}
                        >
                          NOT QUALIFIED
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("QUALIFIED")}
                        >
                          QUALIFIED
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("HOT LEAD")}
                        >
                          HOT LEAD
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("NOT PICKUP")}
                        >
                          NOT PICKUP
                        </li>
                        <li
                          class="dropdown-item"
                          onClick={() => setLeadType("CONVERSION LEAD")}
                        >
                          CONVERSION LEAD
                        </li>
                      </ul>
                    </div> */}
                  </th>
                  <th scope="col">Report_Section</th>
                </tr>
              </thead>
              <tbody>
                {Leads && LeadType === "ALL LEAD"
                  ? Leads.slice(pagination * rowsPerPage, pagination * rowsPerPage + rowsPerPage).map((lead, index) => (
                      <tr key={index}>
                        <th scope="row">{index+1}</th>
                        {leadsKey.map((data) => (
                          <>
                            <td>{lead[data]}</td>
                          </>
                        ))}
                        <td>
                          <div
                            class="select"
                            style={{
                              width: "13em",
                            }}
                          >
                            <select
                              value={lead?.LeadType}
                              onChange={(e) => setLeadTypeHandler(e, lead)}
                            >
                              <option value="NEW LEAD">NEW LEAD</option>
                              <option value="NOT QUALIFIED">
                                NOT QUALIFIED
                              </option>
                              <option value="QUALIFIED">QUALIFIED</option>
                              <option value="HOT LEAD">HOT LEAD</option>
                              <option value="NOT PICKUP">NOT PICKUP</option>
                              <option value="CONVERSION LEAD">
                                CONVERSION LEAD
                              </option>
                            </select>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center">
                            <button
                              className="icon-button"
                              onClick={(e) => query(lead, e)}
                            >
                              <ArrowForwardIcon />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  : Leads.slice(pagination * rowsPerPage, pagination * rowsPerPage + rowsPerPage).filter((e) => e.LeadType === LeadType).map(
                      (lead, index) => (
                        <tr key={index}>
                          <th scope="row">{index+1}</th>
                          {leadsKey.map((data) => (
                            <>
                              <td>{lead[data]}</td>
                            </>
                          ))}
                          <td>
                            <div
                              class="select"
                              style={{
                                width: "13em",
                              }}
                            >
                              <select
                                value={lead?.LeadType}
                                onChange={(e) => setLeadTypeHandler(e, lead)}
                              >
                                <option value="NEW LEAD">NEW LEAD</option>
                                <option value="NOT QUALIFIED">
                                  NOT QUALIFIED
                                </option>
                                <option value="QUALIFIED">QUALIFIED</option>
                                <option value="HOT LEAD">HOT LEAD</option>
                                <option value="NOT PICKUP">NOT PICKUP</option>
                                <option value="CONVERSION LEAD">
                                  CONVERSION LEAD
                                </option>
                              </select>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center align-items-center">
                              <button
                                className="icon-button"
                                onClick={(e) => query(lead, e)}
                              >
                                <ArrowForwardIcon />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
              </tbody>
            </table>
          </div>
        )}
        {Leads && Leads.length === 0 && (
          <>
            <div className="m-5">
              <h1 className="text-white text-center ">
                {" "}
                No leads on This form{" "}
              </h1>
            </div>
          </>
        )}
      {Leads &&  Leads.length !== 0 &&  <div style={{
          backgroundColor: "cadetblue"
        }}>
        <TablePagination
          component="div"
          className="text-white"
          rowsPerPageOptions={[10, 25, 100]}
          count={Leads.length}
          page={pagination}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </div>}
        
      </div>
    </>
  );
};

export default Leads;
