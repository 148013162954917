import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import { axiosInstance } from '../../config';
import { Link } from "react-router-dom";
import { AiFillDelete } from 'react-icons/ai';
import { BiEdit } from "react-icons/bi";
import { useSelector } from 'react-redux';
import Navbar from '../../components/navbar/Navbar';
import TableTransition from '../../components/TableTransition/TableTransition';


const Servicedashboard = () => {
  const { user } = useSelector((state) => state.user);
    const [serviceName, setServiceName] = useState();
    const [AllKey, setAllKey] = useState();
    const [del, setDel] = useState(false);
  
    useEffect(() => {
      getServiceName();
    }, [del]);
  
    const getServiceName = async () => {
      try {
        const { data } = await axiosInstance.get(`/api/services?User=${user._id}`);
        const leadsKey = Object.keys(data.data[0]);
        console.log(data.data,"services");
        console.log(leadsKey,"leadsKey");
        setAllKey(leadsKey.filter((e) => e !== "_id" && e !== "User" && e !== "createdAt"  && e !== "updatedAt" && e !== "__v" ));
        setServiceName(data.data);
      } catch (error) {
        console.log("error")
        alert(error.response.data.message);
      }
    };
  
   
  
    const deleteserviceName = async (id) => {
      var answer = window.confirm("Are you sure To delete?");
      try {
        if (answer) {
          const { data } = await axiosInstance.delete(`/api/services/${id}`);
          if (data.success) {
            alert(data.message);
          }
        }
        getServiceName()
      } catch (error) {
        alert(error.response.data.message);
      }
    };
  
  return (
    <>
    <Sidebar/>
    
    <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar  name={"Services"}/>
        <hr className='hrTag' />
        {/* <!--End Top Nav --> */}
        <div className="row ">
          <div className="col-md-9"></div>
          <div className="col-md-3 btn btn-secondary  mx-5"><Link to="/client/addservice" className="text-white nav-link">Add New Service</Link></div>
        </div>
         <TableTransition AllKey={AllKey}  data={serviceName} deleteFunction={deleteserviceName} editUrl ={`/client/editservice`}/>
      </div>
   



    </>
  )
}

export default Servicedashboard