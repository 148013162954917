import React, { useState, useEffect, createContext } from "react";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { Button } from "react-bootstrap";
import Navbar from "../../components/navbar/Navbar";

const CreateApplicarion = () => {
  const navigate = useNavigate();

  const [AppID, setAppID] = useState();
  const [AppSecret, setAppSecret] = useState();
  const [accessToken, setaccessToken] = useState();
  // const [AppName, setAppName] = useState();

 
  const createContextHandler = async (e) => {
    e.preventDefault();
    try {
      if (!AppID || !AppSecret || !accessToken ) {
        return alert("Please fill all fields");
      }

      const { data } = await axiosInstance.post(`/api/app`, {
        AppID,
        AppSecret,
        accessToken,
      });
      if (data.success) {
        navigate(`/admin/AddUser/${AppID}`)
        alert(data.message);
      }
     
    } catch (error) {
      console.log(error,"check error");
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true} />
        <hr className="hrTag" />
        <div className="row mx-2 ">
          <div className="toogler">
            <h3 className="text-white">CREATE APP ( FIRST STEP ) </h3>
          </div>

          <form
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}
            onSubmit={createContextHandler}
          >
       
            <div className="col-md-8 align-items-center">
              <div className="d-flex ">
                <label className="text-white">CLIENT APP ID</label>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://developers.facebook.com/apps"
                  class="badge badge-info"
                >
                  GET APPID
                </a>
              </div>

              <input
                className="form-control mt-2 mb-2"
                type="text"
                value={AppID}
                onChange={(e) => setAppID(e.target.value)}
                placeholder="App Id"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <div className="d-flex ">
                <label className="text-white">CLIENT APP SECRET</label>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://developers.facebook.com/apps/${AppID}/settings/basic/`}
                  class="badge badge-info"
                >
                  GET APP SECRET
                </a>
              </div>
              <input
                className="form-control mt-2 mb-2 "
                type="text"
                value={AppSecret}
                onChange={(e) => setAppSecret(e.target.value)}
                placeholder="App Secret"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
            
              <div className="d-flex ">
              <label className="text-white">CLIENT ACCESS TOKEN</label>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`https://developers.facebook.com/tools/explorer`}
                  class="badge badge-info"
                >
                  GET ACCESS TOKEN
                </a>
              </div>
              <input
                className="form-control mt-2 mb-2 "
                type="text"
                value={accessToken}
                onChange={(e) => setaccessToken(e.target.value)}
                placeholder=" Access Token"
                required
              />
            </div>
            <div className="col-md-8" style={{ padding: "5px" }}>
              <div className="d-flex flex-row">
                <button className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default CreateApplicarion;
