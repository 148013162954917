import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";
import { Link } from "react-router-dom";
import "./leads.css";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import Navbar from "../../components/navbar/Navbar";
import Comments from "./Comments";
import { useSelector } from "react-redux";

const AllLeads = () => {
  const { salesPerson } = useSelector((state) => state.salesPerson);

  const [Leads, setLeads] = useState();
  const [leadsKey, setLeadsKey] = useState([]);
  const [LeadType, setLeadType] = useState();
  const [allForm, serAllForms] = useState();
  const [selectedFormId, setSelectedFormId] = useState();

  useEffect(() => {
    getForms();
    
  }, []);
  useEffect(() => {
   console.log(salesPerson,"salesPerson");

  }, []);

  const getForms = async () => {
    try {
      const {
        data: { isExists },
      } = await axiosInstance.post(`/api/page/getAllSalesPersonForms`, {
        Services: salesPerson.Service,
      });
      console.log(
        isExists.filter((e) => e.formName),
        "success check"
      );
      serAllForms(isExists.filter((e) => e.formName));
    } catch (error) {}
  };

  const getLeads = async (e) => {
    let selectedForm
    if(e){
      selectedForm= JSON.parse(e.target.value);
      setSelectedFormId(selectedForm)
    }else{
      selectedForm= selectedFormId
    }
    // const selectedFormId = JSON.parse(e.target.value);
    setLeads([]);
    setLeadsKey([]);
    try {
      const {
        data: { isExists },
      } = await axiosInstance.post(`/api/page/getLeadsData`, {
        key: "salesPerson.id",
        value: salesPerson._id, // sales login sales person id
        formId: selectedForm.form_id,
      });

      if (isExists.leads.length === 0) {
        setLeads([]);
        setLeadsKey([]);
      }else{
        const leadsKey = Object.keys(isExists.leads[0]);
        setLeads(isExists.leads);
        setLeadsKey(
          leadsKey.filter(
            (e) =>
              e !== "LeadType" &&
              e !== "Lead_Created_Date" &&
              e !== "salesPerson" &&
              e !== "comment" &&
              e !== "LeadAssignedTime" &&
              e !== "newDate" &&
              e !== "isAssigned"
          )
        );
      }
     
    } catch (error) {
      console.log("ERROR RUNNING");
      // alert(error.response.data.message);
    }
  };


  const setLeadTypeHandler = async (e, lead) => {
    try {
      console.log("Running lead ", e.target.value);
      const {
        data: { data },
      } = await axiosInstance.put("/api/page/setLeadType", {
        form_id:selectedFormId.form_id,
        leadgen_id: lead.leadgen_id,
        LeadType: e.target.value,
      });
      console.log(data,"data selectedFormId");
      if (data.matchedCount !== 1) {
        alert("lead not update ");
      }
      console.log("running 1");
      getLeads();
      console.log("running 2");
    } catch (error) {
      alert("lead not update");
    }
  };

  useEffect(() => {
    setLeadType("ALL LEAD");
  }, []);

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar name={"Leads"} isSalesPerson={true}/>
        <hr className='hrTag' />
        {/* <!--End Top Nav --> */}
       

        <div className="row ">
          <div className="col-md-4 select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Form
            </label>

            <select onChange={(e) => getLeads(e)}>
              <option selected hidden>
                select Form
              </option>
              {allForm?.length === 0 && <option>no form</option>}
              {allForm &&
                allForm.map((form, i) => (
                  <option key={i} value={JSON.stringify(form)}>
                    {form.formName}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-5  select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Leads Type
            </label>
            <select onChange={(e) => setLeadType(e.target.value)}>
              <option value="ALL LEAD">ALL LEAD</option>
              <option value="NEW LEAD">NEW LEAD</option>
              <option value="NOT QUALIFIED">NOT QUALIFIED</option>
              <option value="QUALIFIED">QUALIFIED</option>
              <option value="HOT LEAD">HOT LEAD</option>
              <option value="NOT PICKUP">NOT PICKUP</option>
              <option value="CONVERSION LEAD">CONVERSION LEAD</option>
            </select>
          </div>
        </div>
        <div class="table-responsive">
        {Leads && Leads.length !== 0 &&   (<table class="table table-striped table-dark  table-bordered  overflow-x mt-3">
            <thead>
              <tr>
                <th scope="col">#</th>
                {leadsKey && 
                  leadsKey.map((data) => <th scope="col">{data}</th>)}
                <th scope="col">Action</th>
                <th scope="col">Add_Comment</th>
              </tr>
            </thead>
            <tbody>
              {Leads && 
                (LeadType === "ALL LEAD"
                  ? Leads.map((lead, index) => (
                      <tr key={lead.leadgen_id}>
                        <th scope="row">{index+1}</th>
                        {leadsKey.map((data) => (
                          <>
                            <td>{lead[data]}</td>
                          </>
                        ))}

                        
                        <td>
                          <div
                            class="select"
                            style={{
                              width: "13em",
                            }}
                          >
                            <select
                              value={lead?.LeadType}
                              onChange={(e) => setLeadTypeHandler(e, lead)}
                            >
                              <option value="NEW LEAD">NEW LEAD</option>
                              <option value="NOT QUALIFIED">
                                NOT QUALIFIED
                              </option>
                              <option value="QUALIFIED">QUALIFIED</option>
                              <option value="HOT LEAD">HOT LEAD</option>
                              <option value="NOT PICKUP">NOT PICKUP</option>
                              <option value="CONVERSION LEAD">CONVERSION LEAD</option>
                            </select>
                          </div>
                        </td>


                        <td key={lead.leadgen_id}>
                          <Comments key={lead.leadgen_id} lead={lead} getLeads={getLeads} selectedFormId={selectedFormId.form_id} />
                        </td>
                      </tr>
                    ))
                  : Leads.filter((e) => e.LeadType === LeadType).map(
                      (lead, index) => (
                        <tr key={index}>
                          <th scope="row">{index}</th>
                          {leadsKey.map((data) => (
                            <>
                              <td>{lead[data]}</td>
                            </>
                          ))}
                          <td>
                            <div
                              class="select"
                              style={{
                                width: "13em",
                              }}
                            >
                              <select
                                value={lead?.LeadType}
                                onChange={(e) => setLeadTypeHandler(e, lead)}
                              >
                                <option value="NEW LEAD">NEW LEAD</option>
                                <option value="NOT QUALIFIED">
                                  NOT QUALIFIED
                                </option>
                                <option value="QUALIFIED">QUALIFIED</option>
                                <option value="HOT LEAD">HOT LEAD</option>
                                <option value="NOT PICKUP">NOT PICKUP</option>
                                <option value="CONVERSION LEAD">CONVERSION LEAD</option>
                              </select>
                            </div>
                          </td>
                          <td key={lead.leadgen_id}>
                            <Comments key={lead.leadgen_id} lead={lead} getLeads={getLeads} />
                          </td>
                        </tr>
                      )
                    ))}
            </tbody>
          </table>)}
          {Leads && Leads?.length === 0 && (
          <>
            <div className="m-5">
              <h1 className="text-white text-center ">
                {" "}
                No New Leads To Assign
              </h1>
            </div>
          </>
        )}
        </div>
      </div>
    </>
  );
};

export default AllLeads;
