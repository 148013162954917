import React, { useState } from "react";
import "../../assets/css/login.css";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
const ResetPassword = () => {
  const [Email, setEmail] = useState();
  const [IsEmailSend, setIsEmailSend] = useState(true);
  const [Otp, setOtp] = useState();
  const [Password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate()
  const sendResetMail = async() => {
    setIsLoading(true)
    if(!Email){
      return alert("Please enter your email address")
    }
    try {
     
      const { data } = await axiosInstance.post(
        `/api/user/forgotPassword`,
        { Email}
      );
     if(data.success) {
      setIsEmailSend(false)
      alert(data.message)
     }
      
    } catch (error) {
      setIsLoading(false);
      console.log(error ,"in email send");
    }
    setIsLoading(false)
  };
  const updatedPassword = async() => {
    setIsLoading(true)
    try {
      const { data } = await axiosInstance.post(
        `/api/user/setNewPassword`,
        {Email,Otp,Password }
      );
      if(data.success) {
        alert(data.message);
        navigate("/login")
      }
      setIsLoading(false)
     
    } catch (error) {
      setIsLoading(false);
      alert(error.response.data.message);
      console.log(error.response.data.message ,"in updatedPassword");
    }
  };
  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              alt="hey"
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
            {IsEmailSend ? (
              <>
                <div className="reset-password-section text-center boxShadow">
                  {/* <h3><i className="fa fa-lock fa-4x"></i></h3> */}
                  <h2 className="text-center">Forgot Password?</h2>
                  <p>You can reset your password here.</p>
                  <div className="panel-body">
                    <form>
                      <div className="form-group">
                        <span className="input-group-addon">
                          <i className="glyphicon glyphicon-envelope color-blue"></i>
                        </span>
                        <input
                          id="email"
                          name="email"
                          placeholder="email address"
                          className="form-control form-control-lg"
                          value={Email}
                          onChange={(e) => setEmail(e.target.value)}
                          type="email"
                        />
                      </div>

                      <div className="form-group mt-2">
                        {!isLoading?<button
                          onClick={() => sendResetMail()}
                          type="button"
                          className="btn bgYellow w-100"
                        >
                          Reset Password
                        </button>:<button
                          
                          type="button"
                          className="btn bgYellow w-100"
                        >
                          loading....
                        </button>}
                        <p className="small fw-bold mt-2 pt-1 mb-0">
                          Remember your password ?{" "}
                          <Link to="/login" className="link-danger">
                            Login
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="reset-password-section text-center boxShadow">
                  {/* <h3><i className="fa fa-lock fa-4x"></i></h3> */}
                  <h2 className="text-center">Update password?</h2>
                  <p>You can reset your password here.</p>
                  <div className="panel-body">
                    <form>
                      <div className="form-group">
                        <input
                          value={Otp}
                          onChange={(e) => setOtp(e.target.value)}
                          placeholder="Enter OTP"
                          className="form-control form-control-lg"
                          type="text"
                        />
                      </div>
                      <div className="form-group mt-3 mb-3">
                        <input
                          value={Password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter Password"
                          className="form-control form-control-lg"
                          type="text"
                        />
                      </div>
                     

                      <div className="form-group mt-2">
                       {!isLoading?(<button
                          onClick={() => updatedPassword()}
                          type="button"
                          className="btn bgYellow w-100"
                        >
                          Update Password
                        </button>):(<button
                        
                          type="button"
                          className="btn bgYellow w-100"
                        >
                          Loading...
                        </button>)} 
                        <p className="small fw-bold mt-2 pt-1 mb-0">
                          Remember your password ?{" "}
                          <Link to="/login" className="link-danger">
                            Login
                          </Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
