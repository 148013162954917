import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { axiosInstance } from "../../config";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  
  const [AppId, setAppId] = useState();
  const [PageId, setPageId] = useState();
 

  const getUser = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/user/${id}`);
      console.log(data?.data?.PageId, "users");
      // setUser(data?.data);
      setAppId(data?.data?.AppId);
      setEmail(data?.data?.Email);
      setName(data?.data?.Name);
      setPassword(data?.data?.Password);
      setPageId(data?.data?.PageId?.join());
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(`/api/user/${id}`, {
        AppId,
        Password,
        Email,
        Name,
      });
      if (data.success) {
        navigate("/admin/user")
        alert(data.message);
      }
      console.log(data, "create data");
    } catch (error) {
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true} />
        < hr className='hrTag'/>
        <div className="row mx-2 ">
          <div className="toogler">
            <h3 className="text-white">EDIT CLIENT </h3>
          </div>

          <form>
            <div className="col-md-8 align-items-center">
              <label className="text-white"> CLIENT NAME</label>
              <input
                className="form-control mt-3 mb-3 "
                type="text"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                placeholder="  Name"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">CLIENT EMAIL</label>
              <input
                className="form-control mt-3 mb-3"
                type="text"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">CLIENT APP ID</label>
              <input
                className="form-control mt-3 mb-3"
                type="test"
                value={AppId}
                onChange={(e) => setAppId(e.target.value)}
              />
            </div>

            <div className="col-md-8 align-items-center">
              <label className="text-white">CLIENT PASSWORD</label>
              <input
                className="form-control mt-3 mb-3"
                type="text"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white"> Associate pages</label>
              <textarea value={PageId} readOnly type="text" className="form-control mt-3 mb-3" rows="4" cols="50"/>
            </div>
          </form>
          <div className="col-md-8" style={{ padding: "5px" }}>
            <div className="d-flex flex-row mt-2 mb-2 ">
              <button className="btn btn-success " onClick={updateUser}>
                UPDATE CLIENT
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditUser;
