import React, { useState } from "react";
import "../../assets/css/profile.css";
import { axiosInstance } from "../../config";

const ChangePasswordPage = ({user}) => {
  const [IsLoading, setIsLoading] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const updatedPassword = async () => {
    setIsLoading(true);
    try {
      const { data } = await axiosInstance.post(`/api/user/UpdatePassword/${user._id}`, {
        oldPassword,
        newPassword,
        confirmPassword,
      }
      );
      console.log("data","check data");
      if (data.success) {
        alert(data.message);
      }
      setIsLoading(false);
    } catch (error) {
        setIsLoading(false);
      alert(error.response.data.message);
      console.log(error, "in updatedPassword");
    }
  };
  return (
    <div className="col-md-9">
      <div className="my-3 p-3 bg-body rounded shadow-sm">
        <h6 className="border-bottom pb-2 mb-0 mb-3 yellow">Change Password</h6>

        <div className="row">
          <div className="col-4">
            <p>Your Password must contain.</p>
            <p>
              {" "}
              <i className="fa fa-check yellow"></i> At least 8 characters.
            </p>
            <p>
              {" "}
              <i className="fa fa-check yellow"></i> at least 1 number.
            </p>
            <p>
              {" "}
              <i className="fa fa-check yellow"></i> at least 1 special
              character.
            </p>
            <p>
              {" "}
              <i className="fa fa-check yellow"></i> mixed case characters.
            </p>
          </div>
          <div className="col-8">
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Current Password
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Current Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={oldPassword}
                    onChange={(e)=>setOldPassword(e.target.value)}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-key"></i>
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  New Password
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="New Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={newPassword}
                    onChange={(e)=>setNewPassword(e.target.value)}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-key"></i>
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Confirm New Password
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Confirm New Password"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={confirmPassword}
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <i className="fa fa-key"></i>
                  </span>
                </div>
              </div>
              <hr />
              {IsLoading ? (
                <>
                  <button className="btn bgYellow">Loading....</button>
                </>
              ) : (
                <button
                  onClick={() => updatedPassword()}
                  className="btn bgYellow"
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
