import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { BiEdit } from "react-icons/bi";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar/Navbar";

const SalesPerson = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user,"12345")
  const [User, setUser] = useState();
  const [AllKey, setAllKey] = useState();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/salesPerson?User=${user._id}`);
       const leadsKey = Object.keys(data.data[0]);
        console.log(data.data,"SALESPerson 123");
        console.log(leadsKey.filter((e) => e !== "_id" && e !== "createdAt"  && e !== "updatedAt" && e !== "__v" ),"SALESPersonleadsKey");
        setAllKey(leadsKey.filter((e) => e !== "_id" && e !== "createdAt"  && e !== "updatedAt" && e !== "__v" && e !== "Service" && e !== "User" ));
      setUser(data.data);
    } catch (error) {
      console.log("error")
      alert(error.response.data.message);
    }
  };
  const deleteUser = async (id) => {
    console.log(id,"check id")
    var answer = window.confirm("Are you sure To delete?");
    try {
      if (answer) {
        const { data } = await axiosInstance.delete(`/api/salesPerson/${id}`);
        if (data.success) {
          alert(data.message);
        }
       
      }
      getUsers()
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar   name={"Sales Person"}/>
        <hr className='hrTag' />
        {/* <!--End Top Nav --> */}
        {/* <h3 className="text-white p-3">Sales Person</h3> */}

        <div className="row ">
          <div className="col-md-9"></div>
          <div className="col-md-3 btn btn-secondary  mx-5"><Link to="/client/addsalesperson" className="text-white nav-link">Add New SalesPerson</Link></div>
        </div>

        <div class="table-responsive">
     { AllKey &&  <table class="table table-striped table-dark  table-bordered  overflow-x mt-3">
        <thead>
          <tr>
            <th scope="col">#</th>
            
            <th  scope="col">Name</th>
            <th  scope="col">Email</th>
            <th  scope="col">Number</th>
            <th  scope="col">Password</th>
            <th  scope="col">Service Name</th>
         
               <th scope="col">EXTRA OPTIONS</th>
            
          </tr>
        </thead>
        <tbody>
          {User &&
            (User.map((value, index) => (
                <tr key={index}>
                  <th scope="row">{index}</th>
                  <th scope="row">{value?.Name}</th>
                  <th scope="row">{value?.Email}</th>
                  <th scope="row">{value?.Number}</th>
                  <th scope="row">{value?.Password}</th>
                  <th scope="row">{value?.Service?.serviceName}</th>
                 
                  <th scope="row">  <div className="col-md-2">
                  <div className="row">
                    <div className="col-md-4">
                      <Link className="col-md-1" to={`/client/editsalesperson/${value?._id}`}>
                        <BiEdit size={25} color="white" />{" "}
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <AiFillDelete
                        color="white"
                        size={25}
                        onClick={() => deleteUser(value?._id)}
                      />
                    </div>
                  </div>
                </div></th>
              
                </tr>
              )))
            }
        </tbody>
      </table>}
    </div>
      </div>
    </>
  );
};

export default SalesPerson;
