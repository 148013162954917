import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import ProfilePage from "../../pages/profile/ProfilePage";
import Navbar from "../../components/navbar/Navbar";

const ClientProfile = () => {
  const [User, setUser] = useState();
  
  const [del, setDel] = useState(false);

  useEffect(() => {
    getUsers();
  }, [del]);

  const getUsers = async () => {
    try {
      const { data } = await axiosInstance.get("/api/salesPerson");
      console.log(data, "salesPerson");
      setUser(data.data.reverse());
    } catch (error) {
      console.log("error")
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont overflow-hidden">
      <Navbar name={"Settings"}/>
        <hr className='hrTag' />
        {/* <!-- Top Nav --> */}
        
        <ProfilePage/>

      </div>
    </>
  );
};

export default ClientProfile;
