import React from "react";
import "./Card.scss";
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ReportIcon from '@mui/icons-material/Report';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Link } from "react-router-dom";
const CardData = ({ heading, value, view, link, parentage, BgColor }) => {


  // return (
  //   <div className={`CardData ${BgColor} `}>
  //     <div className="left">
  //       <span className="CardData-heading ">{heading}</span>
  //       <span className="CardData-value ">{value}</span>
  //       <Link to={link} className="CardData-view">{view}</Link>
  //     </div>
  //     <div className="right">
  //       <div className="CardData-parentage  negative ">
  //         {/* <KeyboardControlKeyIcon /> */}
  //         <span>{parentage}</span>
  //       </div>
  //       <div className="CardData-icon">{icon}</div>
  //     </div>
  //   </div>
  // );
  return (
    // <article class="tile">
    <div className={`CardData ${BgColor}`}>
      <div className="left">
        <span className="CardData-heading ">{heading}</span>
        <span className="CardData-value  ">{value}</span>
        <Link to={link} className="CardData-view">
          {view}
        </Link>
      </div>
      <div className="right">
        <div className="CardData-parentage negative ">
        { BgColor === "two" && <MonetizationOnIcon />}
          <span>{parentage}</span>
          { BgColor === "four" && <TrendingDownOutlinedIcon />}
        { BgColor === "one" && <ReportIcon />}
        { BgColor === "three" && <TimelineIcon />}
    
        </div>
        <span class="CardData-icon">
          
        <ArrowForwardIcon/>
        </span>
        {/* <button class="icon-button">

        <ArrowForwardIcon/>
        </button> */}
        {/* <div className="CardData-icon">{<i class="ph-caret-right-bold"></i>}</div> */}
      </div>
    </div>
    // </article>
  );
};

export default CardData;
