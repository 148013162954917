import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";

// import "./leads.css";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getpages,
  pagedatafailure,
  pagedatasuccess,
} from "../../redux/slice/pageSlice";
import {
  FormsDataFailure,
  FormsDataSuccess,
  getForms,
} from "../../redux/slice/formsSlicer";
import Navbar from "../../components/navbar/Navbar";
const AssignServices = () => {
  const { user } = useSelector((state) => state.user);
  const { page } = useSelector((state) => state.page);
  const { forms } = useSelector((state) => state.form);


  const dispatch = useDispatch();

  const [serviceName, setServiceName] = useState();
  const [selectedPage, setSelectedPage] = useState();
  const [allPages, setAllPages] = useState();
  const [allForm, setAllForm] = useState();
  const [selectedForm, setSelectedForm] = useState();
  const [isLeadsExist, setIsLeadsExist] = useState(true);
  const [ServiceId, setServiceId] = useState();
  const [page_obj_ID, setPage_obj_ID] = useState();
  const [is_Services, setIsServices] = useState(false);
  const [is_ServicesName, setServicesName] = useState();

  useEffect(() => {
    getLeads();
  }, []);

  const getAllPages = async () => {
    if (page.length !== 0) {
      return;
    }


    dispatch(getpages());
    try {
      const {
        data: { data },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${user?.App?.AccountId}/accounts?access_token=${user?.App?.PermanentAccessToken}`
      );
      dispatch(pagedatasuccess(data));
      setAllPages(data);
      console.log(data, "check data");
    } catch (error) {
      dispatch(pagedatafailure());
      console.log(error, "error");
      return false;
    }
  };

  const getAllForm = async (e) => {
    setSelectedPage(JSON.parse(e.target.value));
   
    const pageData = JSON.parse(e.target.value); // page object

    try {
      dispatch(getForms());
      if (forms[pageData.id]) {
        setAllForm(forms[pageData.id]);
        return false;
      }
      console.log("----------------- API FORM CALLED ------------");
      const {
        data: { data: formData },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${pageData.id}/leadgen_forms?access_token=${pageData.access_token}`
      );
      dispatch(
        FormsDataSuccess({
          ...forms,
          [pageData.id]: formData,
        })
      );

      setAllForm(formData);
    } catch (error) {
      dispatch(FormsDataFailure(error));
    }
  };

  const getLeads = async (e) => {
   
    const formData = JSON.parse(e.target.value);
    setSelectedForm(formData)
    try {
      const {
        data: { isExists },
      } = await axiosInstance.post("/api/page/findUserPages", {
        page_id: selectedPage.id,
        form_id: formData.id,
      });
      console.log(isExists,"isExists susses");
      if(isExists.Services){
        setIsServices(true)
        setServicesName(isExists.Services.serviceName)
        setIsLeadsExist(true);
        return ;

      }else{
        setIsServices(false)
      }
      if (!isExists._id) {
        setIsLeadsExist(false);
      } else {
        setPage_obj_ID(isExists._id);
        setIsLeadsExist(true);
      }
      // console.log(isExists._id, "isExists");
    } catch (error) {
      console.log(error, "isExists");
    }
  };

  const getSalesPerson = async (e) => {
    try {
   
      const services = JSON.parse(e.target.value);
      console.log(services, "check event");
      setServiceId(services._id)
    } catch (error) {}
  };

  const getServiceName = async () => {
    try {
      const { data } = await axiosInstance.get(
        `/api/services?User=${user._id}`
      );

      setServiceName(data.data);
    } catch (error) {
      console.log("error", error);
      // alert(error.response.data.message);
    }
  };
  const AssignServicesHandler = async () => {
    console.log(selectedPage,"selectedForm,selectedForm",selectedForm);
    try {
      const { data } = await axiosInstance.put(
        `/api/page/updatePage/${page_obj_ID}`,{
          "Services":ServiceId,
          "pageName":selectedPage.name,
          "formName":selectedForm.name

        }
      );
      console.log(data,"data");
      alert(data.message);
    } catch (error) {}
  };

  useEffect(() => {
    getServiceName();
  }, []);

  useEffect(() => {
    getAllPages();
  }, []);

  return (
    <>
      <Sidebar name={"Add Services To Form"} />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar name={"Add Services To Form"} />
        <hr className="hrTag" />
        {/* <!--End Top Nav --> */}
        <div className="col">
          <div className="col-md-8  mb-4 select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Page
            </label>
            <select onChange={(e) => getAllForm(e)}>
              <option selected hidden>
                select page
              </option>
              {page &&
                page.map((page, i) => (
                  <option key={i} value={JSON.stringify(page)}>
                    {page?.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="col-md-8  mb-4 select  mx-5">
            <label className="text-white align-self-center m-3">
              Select Form
            </label>
            <select onChange={(e) => getLeads(e)}>
              <option selected hidden>
                select Form
              </option>
              {!allForm?.length && <option>no form</option>}
              {allForm &&
                allForm.map((form, i) => (
                  <option key={i} value={JSON.stringify(form)}>
                    {form.name}
                  </option>
                ))}
            </select>
          </div>
          {!is_Services && isLeadsExist && (
            <>
              <div className="col-md-8 select  mx-5">
                <label className="text-white align-self-center m-3">
                  Select Service
                </label>
                <select onChange={(e) => getSalesPerson(e)}>
                  <option selected hidden>
                    Select Service
                  </option>
                  {!serviceName?.length && <option>no service</option>}
                  {serviceName &&
                    serviceName.map((service, i) => (
                      <option key={i} value={JSON.stringify(service)}>
                        {service.serviceName}
                      </option>
                    ))}
                </select>
              </div>
              <hr className="hrTag"/>
              <div className="col-md-8  mb-4 mx-5" style={{ padding: "5px" }}>
                <div className="d-flex flex-row mt-2 mb-2 ">
                  <button
                    onClick={AssignServicesHandler}
                    className="btn bgYellow"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </>
          )}

          {!isLeadsExist && (
            <>
              <div className="m-5">
                <h1 className="text-white text-center ">
                  {" "}
                  No leads on This form{" "}
                </h1>
              </div>
            </>
          )}
          {is_Services && (
            <>
              <div className="m-5">
                <h1 className="text-white text-center ">
                  {" "}
                 Already Assigned This Services To {is_ServicesName} {" "}
                </h1>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignServices;
