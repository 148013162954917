import React from "react";
import "../../assets/css/login.css";
import { Link } from "react-router-dom";
import { useState } from "react";
import { axiosInstance } from "../../config";
import { useNavigate } from "react-router-dom";
// import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getuser,
  reseterror,
  userdatafailure,
  userdatasuccess,
} from "../../redux/slice/userSlice";
import {
  getsalesPerson,
  salesPersondatasuccess,
  salesPersonfailure,
} from "../../redux/slice/salespersonSlice";
const LoginPage = () => {
  const { loading } = useSelector((state) => state.user);
  const { loading: salesloader } = useSelector((state) => state.salesPerson);
  const navigate = useNavigate();

  const [userType, setuserType] = useState();

  const [userdata, setuser] = useState({
    email: "",
    password: "",
  });

  const { email, password } = userdata;

  console.log("rr", userdata);

  const dispatch = useDispatch();

  const onChange = (e) => {
    setuser({
      ...userdata,
      [e.target.name]: e.target.value,
    });
  };

  const handlelogin = async (e) => {
    e.preventDefault();
    try {
      if (userType === "Admin") {
        dispatch(getuser());
        const { data } = await axiosInstance.post("/api/user/login", {
          Email: email,
          Password: password,
        });
        if (data.success) {
          console.log("login data",data.data.AppId );
          localStorage.removeItem("tokentwo");
          dispatch(userdatasuccess(data.data));
          alert("Login successful ");
          navigate("/client/dashboard");
          localStorage.setItem("tokenone", data.token);
          await axiosInstance.post("/api/app/GeneratePermanentToken",{
            AppID:data.data.AppId
            })
        } else {
          dispatch(userdatafailure());
        }
      } else if (userType === "Sales Person") {
        dispatch(getsalesPerson());
        const { data } = await axiosInstance.post("/api/salesPerson/login", {
          Email: email,
          Password: password,
        });
        console.log("data", data);
        if (data.success) {
          localStorage.removeItem("tokenone");

          dispatch(salesPersondatasuccess(data.data));
          alert("Login successful ");
          navigate("/salesPerson/viewLeads");
          localStorage.setItem("tokentwo", data.token);
        } else {
          dispatch(salesPersonfailure());
        }
      } else {
        alert("Select Login Type Admin or Sales Person");
      }
    } catch (error) {
      console.log("error", error);
      alert(error.response.data.message);
      dispatch(reseterror());
    }
  };

  let loader;

  if (userType === "Sales Person") {
    loader = salesloader;
  } else {
    loader = loading;
  }

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-md-9 col-lg-6 col-xl-5">
            <img
              alt="hey"
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
              className="img-fluid"
            />
          </div>
          <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1 ">
            <form className="login-form boxShadow" onSubmit={handlelogin}>
              <div className="d-flex align-items-center my-4">
                <h1 className="text-center fw-normal mb-0 me-3">Sign In</h1>
              </div>
              {/* <!-- Email input --> */}
              <div className="row py-2">
                <div className="col-md-8">
                  <input
                    type="checkbox"
                    value="Admin"
                    id="flexCheckDefault"
                    checked={userType === "Admin" ? true : false}
                    onChange={(e) => setuserType(e.target.value)}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Admin
                  </label>{" "}
                  &nbsp;&nbsp;&nbsp;
                  <input
                    type="checkbox"
                    value="Sales Person"
                    checked={userType === "Sales Person" ? true : false}
                    id="flexCheckDefault"
                    onChange={(e) => setuserType(e.target.value)}
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Sales Person
                  </label>
                </div>
                <div className="col-md-4"></div>
              </div>
              <div className="form-outline mb-4">
                <label className="form-label" htmlFor="form3Example3">
                  Email address
                </label>
                <input
                  type="email"
                  id="form3Example3"
                  name="email"
                  onChange={onChange}
                  required
                  className="form-control form-control-lg"
                  placeholder="Enter a valid email address"
                />
              </div>

              {/* <!-- Password input --> */}
              <div className="form-outline mb-3">
                <label className="form-label" htmlFor="form3Example4">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="form3Example4"
                  onChange={onChange}
                  required
                  className="form-control form-control-lg"
                  placeholder="Enter password"
                />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                {/* <!-- Checkbox --> */}
                <div className="form-check mb-0">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    value=""
                    id="form2Example3"
                  />
                  <label className="form-check-label" htmlFor="form2Example3">
                    Remember me
                  </label>
                </div>
                <Link to="/reset-password" className="text-body">
                  Forgot password?
                </Link>
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                {/* <Link to="/" type="button"  className="btn bgYellow w-100 ">
                  Login
                </Link> */}
                <div class="d-grid py-4">
                  {loader ? (
                    <button
                      type="submit"
                      class="btn text-light main-bg  text-dark bgYellow "
                    >
                      Loading
                    </button>
                  ) : (
                    <button
                      type="submit"
                      class="btn text-light main-bg text-dark bgYellow"
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginPage;
