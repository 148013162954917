
import React, { useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
 
  Tooltip,
 
  Label
} from "recharts";




const Chart = ({graph,key,value}) => {
//  key={"date"} value={"leads"}
console.log(graph,"check graph");

  return (
    <>  {
      
      graph.length !== 0 && <BarChart
    width={1000}
    height={400}
    data={graph}
    margin={{
      top: 5,
      right: 30,
      left: 20,
      bottom: 5
    }}
    barSize={20}
  >
    <XAxis dataKey={"date"}  scale="point" padding={{ left: 20, right: 10 }} >
    <Label value="graph for this month leads" offset={-10} position="bottom" />
  </XAxis>
    <YAxis />
    <Tooltip  />
    {/* <Legend /> */}
    {/* <CartesianGrid strokeDasharray="3 3" /> */}
    <Bar dataKey={"leads"} fill="#8884d8" />
  </BarChart>}
  </>
  
  )
}

export default Chart
