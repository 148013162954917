import { createSlice } from "@reduxjs/toolkit";

export const formSlice = createSlice({
    name: 'forms',
    initialState:{
        forms:{},
        loading:false,
        error:false,
        errormessage:""
    },
    reducers:{
        getForms:(state,action)=>{
            state.loading = true;
            state.error = false
        },
        FormsDataSuccess:(state,action)=>{
            state.loading = false;
            state.forms = action.payload
        },
        FormsDataFailure:(state,action)=>{
            state.loading = false;
            state.error = true;
            state.errormessage = action.payload;
        },
        resetError:(state,action)=>{
            state.forms = {};
            state.loading = false;
            state.error = false;
        }
    }
})


export const {getForms,FormsDataSuccess,FormsDataFailure,resetError} = formSlice.actions;
export default formSlice.reducer;
