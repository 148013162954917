import React, { useEffect, useState } from 'react'
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import CardData from "../../components/Card/CardData";
import "./dashboard.css"
import { axiosInstance } from '../../config';
import Chart from './Chart';
import { useSelector } from 'react-redux';
import { element } from 'prop-types';

const Dashboard = () => {
  const { user } = useSelector((state) => state.user);
  console.log(user,"abcd");
  const [PerviousDayLeads, setPerviousDayLeads] = useState()
  const [PerviousDayUnAssignedLeads, setPerviousUnAssignedDayLeads] = useState()
  const [PerviousDayAssignedLeads, setPerviousAssignedDayLeads] = useState()
  const [PreviousDayConvertedLeads, setPreviousDayConvertedLeads] = useState()
  const [PreviousDayQUALIFIEDLeads, setPreviousDayQUALIFIEDLeads] = useState()
  const [PreviousDayNotQUALIFIEDLeads, setPreviousDayNotQUALIFIEDLeads] = useState()
  
  // const [graph, setGraphData] = useState()
  const [PreviousMonthGraph, setPreviousMonthGraph] = useState()
 
  // const getGraphData = async() =>{
  //   try {
  //     const { data } = await axiosInstance.post(`/api/page/graphData`,{
  //       appId:"1204947790393873",
  //     });
  //     console.log(data.leads.filter((e)=>e.formName),"data.leads");
  //     setGraphData(data.leads.filter((e)=>e.formName) ) 
      
  //   } catch (error) {
  //     console.log("error", error);
  //   }
   
  // }
  const getThisMonthGraph = async() =>{
    try {
      const { data } = await axiosInstance.post(`/api/page/getThisMonthGraph`,{
        appId:user.AppId,
      });
      const now = new Date();
   const  numberOfDays = new Date(now.getFullYear(),now.getMonth()+1,0).getDate()

      let myArr = new Array(numberOfDays);
      myArr.fill({
        date:0,
        leads:0
      }, 0, numberOfDays)
      // const convertDNowToUtc = []
      myArr.forEach((element,i)=>{
        myArr[i] = {date:i+1,leads:0}
      })
      
      data.data.forEach((element) => {
        
         let date = new Date(element._id).getDate()

         myArr[date -1] = {date:date,leads:element.count}
        //  convertDNowToUtc.push({date:date,leads:element.count})
        });
    
      console.log(myArr,"convertDNowToUtc");
      setPreviousMonthGraph(myArr)
      
      
    } catch (error) {
      console.log("error", error);
    }
   
  }

  useEffect(()=>{
    // getGraphData()
    getThisMonthGraph()
  },[])
    const getPreviousLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getTotalNoOfPerviousDayLeads`,{
          appId:user.AppId,
        });
        setPerviousDayLeads(data.perviousDayLeads)
        console.log(data);
       
        
      } catch (error) {
        console.log("error", error);
      }
     
    }
    const getPreviousDayUnAssignedLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getDashBoardData`,{
             appId:user.AppId,
          key:"isAssigned",
          value:false
        });
        setPerviousUnAssignedDayLeads(data.perviousDayLeads)
        console.log(data,"getPreviousLeads1");
      } catch (error) {
        console.log("error", error);
      }
     
    }
    const getPreviousDayAssignedLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getDashBoardData`,{
          appId:user.AppId,
          key:"isAssigned",
          value:true
        });
        setPerviousAssignedDayLeads(data.perviousDayLeads)
        console.log(data,"getPreviousLeads1");
      } catch (error) {
        console.log("error", error);
      }
     
    }
    const getPreviousDayConvertedLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getDashBoardData`,{
          appId:user.AppId,
          key:"LeadType",
          value:"CONVERSION LEAD"
        });
        setPreviousDayConvertedLeads(data.perviousDayLeads)
        console.log(data,"getPreviousLeads1");
      } catch (error) {
        console.log("error", error);
      }
     
    }
    const getPreviousDayQUALIFIEDLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getDashBoardData`,{
          appId:user.AppId,
          key:"LeadType",
          value:"QUALIFIED"
        });
        setPreviousDayQUALIFIEDLeads(data.perviousDayLeads)
        console.log(data,"getPreviousLeads1");
      } catch (error) {
        console.log("error", error);
      }
     
    }
    const getPreviousDayNOTQUALIFIEDLeads = async() =>{
      try {
        const { data } = await axiosInstance.post(`/api/page/getDashBoardData`,{
          appId:user.AppId,
          key:"LeadType",
          value:"NOT QUALIFIED"
        });
        setPreviousDayNotQUALIFIEDLeads(data.perviousDayLeads)
        console.log(data,"getPreviousLeads1");
      } catch (error) {
        console.log("error", error);
      }
     
    }
  


    useEffect(() => {
      getPreviousLeads()
      getPreviousDayUnAssignedLeads()
      getPreviousDayConvertedLeads()
    
    }, [])
    useEffect(() => {
      getPreviousDayAssignedLeads()
      getPreviousDayQUALIFIEDLeads()
      getPreviousDayNOTQUALIFIEDLeads()
    },[])
    

  return (
    <>
    <Sidebar />
    <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
      
        {/* <!--End Top Nav --> */}
        <Navbar name={"DashBoard"} />
        <hr className='hrTag' />
        <div className='homeContainer'>
        <div className='cardsData'>
          <CardData
            heading="Previous Day Leads"
            view="View All Leads"
            value={PerviousDayLeads}
           link= "/client/leads"
           BgColor="three"

            // parentage="10 %"

          />
          <CardData
            heading="Assigned Leads"
            view="Assigned  Leads"
            value={PerviousDayAssignedLeads}
            parentage={PerviousDayUnAssignedLeads}
            link="/client/assign/leads"
            BgColor="one"

          />
          <CardData
            heading="Conversion Leads"
            // view="View all orders"
            value={PreviousDayConvertedLeads}
            // parentage="0 %"
            BgColor="two"

          />
          <CardData
            heading="Qualified Leads"
            // view="View all earnings "
            value={PreviousDayQUALIFIEDLeads}
            parentage={PreviousDayNotQUALIFIEDLeads}
            BgColor="four"
            
          />
        </div>
        {/* <div className='charts-bar '> */}
        {/* {graph && <Chart graph={graph} key={"formName"} value={"numberLeads"}/>} */}
        {/* </div> */}
        <div className='charts-bar '>
        {PreviousMonthGraph && <Chart graph={PreviousMonthGraph}  />}
        </div>
        <div className='home-table'>
          <p className='p'>Latest transactions</p>
          {/* <TableTransition /> */}
        </div>
      </div>
   
      </div>
    
    </>
  )
}

export default Dashboard