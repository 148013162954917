import React, { useEffect, useState } from "react";
import axios from "axios";
import { axiosInstance } from "../../config";

const UserPreferencesPage = ({ user }) => {
  console.log(user, "check this");
  const [leadDistributionType,setleadDistributionType] = useState(user?.App?.leadDistribution)
  const [allPages, setAllPages] = useState();
  const [selectedPage, setSelectedPage] = useState();

  const getAllPages = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(
        `https://graph.facebook.com/v15.0/${user?.App?.AccountId}/accounts?access_token=${user?.App?.PermanentAccessToken}`
      );
      setAllPages(data);
      console.log(data, "check data");
      // pageName = data.find((e) => {
      //   return e.id == page_id
      //   });

      // formName = formData.find((e) => {
      //   return e.id == "474850274348930"
      //   });
    } catch (error) {
      console.log();
      return false;
    }
  };

  const updateClientSelectedPage = async () => {
    console.log(selectedPage, "selectedPage", user?._id);
    try {
      const { data } = await axiosInstance.put(`/api/user/${user?._id}`, {
        selectedPage: {
          name: selectedPage.name,
          id: selectedPage.id,
        },
      });
      if (data.success) {
        alert(data.message);
      }
      console.log(data, "updated");
    } catch (error) {}
  };
  const setLeadDistributionType =async (type) => {
    try {
      const { data } = await axiosInstance.put(`/api/app/${user?.App._id}`, {
        leadDistribution:type
      });
      console.log(type,"user?.App",leadDistributionType,"data");
    } catch (error) {}
  };

  useEffect(() => {
    getAllPages();
    console.log(allPages, "allPages");
  }, []);

  return (
    <div className="col-md-9">
      <div className="my-3 p-3 bg-body rounded shadow-sm">
        <h6 className="border-bottom pb-2 mb-0 mb-3 yellow">
          Basic Preferences
        </h6>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="selectbox" className="form-label">
                Pages
              </label>
              <select
                id="selectbox"
                onChange={(e) => setSelectedPage(JSON.parse(e.target.value))}
                className="form-select"
              >
                {user?.selectedPage ? (
                  <option selected hidden>
                    {user?.selectedPage.name}
                  </option>
                ) : (
                  <option selected hidden>
                    select pages
                  </option>
                )}
                {allPages &&
                  allPages.map((page, i) => (
                    <option key={i} value={JSON.stringify(page)}>
                      {page?.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div
            className="col"
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "end",
            }}
          >
            <div className="mb-3">
            
              <button
                onClick={updateClientSelectedPage}
                className="btn bgYellow"
              >
                update Page
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 p-3 bg-body rounded shadow-sm">
        <h6 className="border-bottom pb-2 mb-0 mb-3 yellow">
          Leads Preferences
        </h6>
        <div className="row">
          <div className="col">
            <div className="mb-3 form-check form-switch">
              <input
                className="form-check-input me-2"
                type="checkbox"
                checked={leadDistributionType==="AUTOMATIC"?true:false}
                onClick={() => setLeadDistributionType("AUTOMATIC")}
                role="switch"
                id="disabledSwitchCheckChecked"
              />
              <label
                className="form-check-label"
                htmlFor="disabledSwitchCheckChecked"
              >
                <p>
                  Automate leads distribution
                  {/* <span className="text-gray-italic">Small Message</span> */}
                </p>
              </label>
            </div>
            <div className="mb-3 form-check form-switch">
              <input
                className="form-check-input me-2"
                type="checkbox"
                onClick={() => setLeadDistributionType("MANUAL")}
                checked={leadDistributionType==="MANUAL"?true:false}
                role="switch"
                id="disabledSwitchCheckChecked"
              />
              <label
                className="form-check-label "
                htmlFor="disabledSwitchCheckChecked"
              >
                <p>
                  {" "}
                  Assigned leads Task to sales person{" "}
                  {/* <span className="text-gray-italic">Small Message </span> */}
                </p>
              </label>
            </div>

            <hr />
            <button className="btn btn-default bgYellow">
              Update Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPreferencesPage;
