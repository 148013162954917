import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { axiosInstance } from "../../config";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";

const EditApp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [AppID, setAppID] = useState();
  const [AppSecret, setAppSecret] = useState();
  const [Pages, setPages] = useState();
  const [PermanentAccessToken, setPermanentAccessToken] = useState();

 

  const getApp = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/app/${id}`);
      console.log(data.data, "users");
      // setUser(data?.data);
      setAppID(data?.data?.AppID);
      setAppSecret(data?.data?.AppSecret);
      setPages(data?.data?.Pages?.join());
      setPermanentAccessToken(data?.data?.PermanentAccessToken);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(`/admin/app${id}`, {});
      if (data.success) {
        navigate("/admin/app");
        alert(data.message);
      }
      console.log(data, "create data");
    } catch (error) {
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

  useEffect(() => {
    getApp();
  }, []);

  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true} />
        < hr className='hrTag'/>
        <div className="row mx-2 ">
          <div className="toogler">
            <h3  className="text-white">EDIT APP</h3>
          </div>

          <form>
          <div className="col-md-8 align-items-center">
              <label className="text-white"> COMPANY NAME</label>
              <input
                className="form-control mt-2 mb-2"
                type="text"
                value={AppID}
                onChange={(e) => setAppID(e.target.value)}
                placeholder="COMPANY NAME"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white"> AppID</label>
              <input
                className="form-control mt-2 mb-2"
                type="text"
                value={AppID}
                onChange={(e) => setAppID(e.target.value)}
                placeholder="AppID"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white"> AppSecret</label>
              <input
                className="form-control mt-2 mb-2 "
                type="text"
                value={AppSecret}
                onChange={(e) => setAppSecret(e.target.value)}
              />
            </div>
        
            <div className="col-md-8 align-items-center">
              <label  className="text-white">Associate pages</label>
              <textarea
                value={Pages}
                readOnly
                type="text"
                className="form-control mt-2 mb-2"
                rows="4"
                cols="50"
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white">Permanent Access Token</label>
              <textarea
                value={PermanentAccessToken}
                readOnly
                type="text"
                className="form-control mt-2 mb-2"
                rows="4"
                cols="50"
              />
            </div>
          </form>
          <div className="col-md-8" style={{ padding: "5px" }}>
            <div className="d-flex flex-row">
              <button className="btn btn-success" onClick={updateUser}>
                UPDATE APP
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditApp;
