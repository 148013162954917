import React, { useState } from "react";
import { axiosInstance } from "../../config";

const Comments = ({lead,getLeads,selectedFormId}) => {
  const [comment, setComment] = useState(lead?.comment);

  const addOrEditComment = async (lead) => {
    console.log(lead, "check data running" );
    try {
      const {
        data: { data },
      } = await axiosInstance.put("/api/page/addOrEditComment", {
        form_id: selectedFormId,
        leadgen_id: lead?.leadgen_id,
        comment: comment,
      });
      if (data.matchedCount === 1) {
        alert("comment added successfully");
        getLeads()

      }
      console.log(data, "check data");
      getLeads();
    } catch (error) {
     
    }
  };
  return (
    <div className="col">
      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
      <button onClick={() => addOrEditComment(lead)}>ADD COMMENT</button>
    </div>
  );
};

export default Comments;
