import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { logouthandler } from '../../redux/slice/salespersonSlice';
import "./sidebar.css"
const Sidebar = () => {
    const dispatch = useDispatch()
    const LogoutHandler=(e) =>{
        e.preventDefault();
    const logOut = window.confirm("do you want to log out")
    if(logOut){
        localStorage.clear()
        dispatch(logouthandler())
        // Navigate("/")
        
    }
      
    }
    return (
        <>
            <div style={{height:"100vh"}} className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column" id="sidebar">
                <ul className="nav flex-column text-white w-100 ">
                    <a href=" " className="nav-link h6 text-white my-2">
                       SALES-PERSON DASHBOARD
                    </a>
                    < hr className='hrTag'/>
                    <Link to="/salesPerson/viewLeads" className="nav-link">
                        <i className="bx bx-body text-white"></i>
                        <span className="mx-2 text-white">View Leads</span>
                    </Link>
                    <Link to="/salesPerson/Profile" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white"> Profile </span>
                    </Link>
                    <Link onClick={LogoutHandler} className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white"> Logout </span>
                    </Link>
               
                  
                </ul>

            </div>


        </>
    )
}

export default Sidebar