import React, { useState, useEffect, createContext } from "react";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../config";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar/Navbar";


const AddSalesPerson = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [Number, setNumber] = useState();
  const [selectedServiceName, setselectedServiceName] = useState();
  

  console.log("email",Name,Email,Password,Number,selectedServiceName)

  const [serviceName, setserviceName] = useState();


  useEffect(() => {
    getServices()
  }, [])


  const getServices = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/services?User=${user._id}`);
      
      setserviceName(data.data);
    } catch (error) {
      console.log("error",error)
    }
  }










  const createUser = async (e) => {
    e.preventDefault();
    try {
      if (
        !Name ||
        !Password ||
        !Email 
      ) {
        return alert("Please fill all fields");
      }
      if(!selectedServiceName){
     
      
        return alert("Please selected or Create a new service");
      }

      const { data } = await axiosInstance.post(`/api/salesPerson`, {
        Password,
        Email,
        Name,
        Number,
        User:user._id,
        Service:selectedServiceName,
      });
      if (data.success) {
        navigate("/client/salesperson")
        alert(data.message);
      }
      console.log(data, "create data");
    } catch (error) {
      alert("something went wrong ");
      console.log(error, "error creating data");
    }
  };








  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <Navbar  name={"Add Sales Men"}/>
        <hr className='hrTag' />
        {/* <!--End Top Nav --> */}
        {/* <h3 className="text-white p-3"> Sales Person💻 📱</h3> */}
        <div className="row mx-2 ">
        
          <form
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}
            onSubmit={createUser}
          >
            <div className="col-md-8 align-items-center">
              <label className="text-white  mt-2 mb-2">Name</label>
              <input
                className="form-control"
                type="text"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Sales person Name"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white  mt-2 mb-2">Email</label>
              <input
                className="form-control "
                type="text"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Sales person Email"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white  mt-2 mb-2">Number</label>
              <input
                className="form-control "
                type="text"
                value={Number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Sales Person Number"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white  mt-2 mb-2">Password</label>
              <input
                className="form-control "
                type="text"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Sales Person Password"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white  mt-2 mb-2">Service Name</label>
              <select class="form-select" aria-label="Default select example"  onChange={(e)=>setselectedServiceName(e.target.value)}>
              <option selected hidden required value={false}>
              Select Service Name
              </option>
              
                {
                  serviceName && serviceName.map((service) => (
                    <>
                      <option  value={service._id}>{service.serviceName}</option>

                    </>
                  ))
                }
              </select>
            </div>
            <div className="col-md-8" style={{ padding: "10px" }}>
              <div className="d-flex flex-row">
                <button className="btn bgYellow  mt-2 mb-2">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddSalesPerson;
