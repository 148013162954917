import React from 'react'
import { AiFillDelete } from 'react-icons/ai'
import { BiEdit } from 'react-icons/bi'
import { Link } from 'react-router-dom'



const TableTransition = ({ AllKey, data,deleteFunction,editUrl }) => {

  return (

    <div class="table-responsive">
     { AllKey &&  <table class="table table-striped table-dark  table-bordered  overflow-x mt-3">
        <thead>
          <tr>
            <th scope="col">#</th>
            {AllKey  &&
              AllKey.map((data,index) => <th key={index} scope="col">{data}</th>)}
               <th scope="col">EXTRA OPTIONS</th>
               {/* <div className="col-md-2 btn btn-secondary">EXTRA OPTIONS</div> */}
          </tr>
        </thead>
        <tbody>
          {data &&
            (data.map((value, index) => (
                <tr key={index}>
                  <th scope="row">{index}</th>
                  {AllKey.map((element) => (
                    <>
                      <th scope="row">{value[element]}</th>
                    </>
                  ))}
                  <th scope="row">  <div className="col-md-2">
                  <div className="row">
                    <div className="col-md-4">
                      <Link className="col-md-1" to={`${editUrl}/${value?._id}`}>
                        <BiEdit size={25} color="white" />{" "}
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <AiFillDelete
                        color="white"
                        size={25}
                        onClick={() => deleteFunction(value?._id)}
                      />
                    </div>
                  </div>
                </div></th>
              
                </tr>
              )))
            }
        </tbody>
      </table>}
    </div>
  )
}

export default TableTransition




//   const [AllKey, setAllKey] = useState();
//  <TableTransition AllKey={AllKey}  data={serviceName} deleteFunction={deleteserviceName} editUrl ={`/client/editsalesperson`}/>