import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./sidebar.css";
import { logouthandler } from "../../redux/slice/userSlice";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import { reseterror } from "../../redux/slice/pageSlice";
const Sidebar = () => {
  const dispatch = useDispatch();
  const LogoutHandler = (e) => {
    e.preventDefault();
    const logOut = window.confirm("do you want to log out");
    if (logOut) {
      localStorage.clear();
      dispatch(logouthandler());
      dispatch(reseterror());
    }
  };
  const togglehandle = (e) => {
    e.persist();

    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };
  return (
    <>
      <div
        style={{ height: "100vh" }}
        className="side-navbar active-nav d-flex justify-content-between flex-wrap flex-column"
        id="sidebar"
      >
        <ul className="nav flex-column text-white w-100  my-2">
          <div class="app-header-logo">
            <div class="logo mx-3">
              <span class="logo-icon">
                <img src="https://assets.codepen.io/285131/almeria-logo.svg" />
              </span>
              <h4 class="logo-title" onClick={togglehandle}>
                <span>Posilead</span>
                <span>Digisidekick</span>
              </h4>
            </div>
          </div>
          {/* <Link to="/client/dashboard" className="nav-link">
                        <i className="bx bx-body text-white"></i>
                        <span className="mx-2 text-white">Dashboard</span>
                    </Link>
                    <Link to="/client/salesperson" className="nav-link">
                        <i className="bx bx-body text-white"></i>
                        <span className="mx-2 text-white">Sales Person</span>
                    </Link>
                    
                    <Link to="/client/services" className="nav-link">
                        <i className="bx bx-body text-white"></i>
                        <span className="mx-2 text-white">Services</span>
                    </Link>
                    <Link to="/client/AddServices" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">Assign Service</span>
                    </Link>
                    <Link to="/client/leads" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">Leads</span>
                    </Link>
                    <Link to="/client/assign/leads" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">Unassign Lead</span>
                    </Link>
                  
                    <Link to="/client/profile" className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">Settings </span>
                    </Link>

                    <Link onClick={LogoutHandler} className="nav-link">
                        <i className="bx bx-user-check text-white"></i>
                        <span className="mx-2 text-white">Logout</span>
                    </Link> */}
          <div class="app-body-navigation mx-3  my-3">
            <nav class="navigation">
            <Link to="/client/dashboard" >
                        <DashboardCustomizeOutlinedIcon  className="m-1"/>
                     Dashboard
                    </Link>
                    <Link to="/client/services" >
                    <i class="fa-solid fa-retweet"></i>
                       Services
                    </Link>
                    <Link to="/client/leads" >
                    <AssignmentOutlinedIcon  className="m-1"/>
                       Leads
                    </Link>
                    
                   
                    <Link to="/client/AddServices" >
                       <ConnectWithoutContactOutlinedIcon className="m-1"/>
                       Assign Service
                    </Link>
                   
                    <Link to="/client/assign/leads" >
                        <AssignmentLateRoundedIcon  className="m-1"/>
                       Unassign Lead
                    </Link>
                    <Link to="/client/salesperson" >
                     <GroupsOutlinedIcon  className="m-1"/>
                        Sales Person
                    </Link>
                    <Link to="/client/profile" >
                    <i class="fa-sharp fa-solid fa-gear"></i>
                       Settings 
                    </Link>

                    <Link onClick={LogoutHandler}>
                       <LogoutIcon  className="m"/>
                       Logout
                    </Link> 
            </nav>
            <footer class="footer">
              <div>
                DigiSideKick ©<br />
                All Rights Reserved 2023
              </div>
            </footer>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
