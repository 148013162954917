import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Responce = () => {
    const [response, setresponse] = useState({})
    useEffect(() => {
     getresponse()
    }, [])

const getresponse = async() =>{
    try {

        const {data} = await axios.post("http://localhost:5000/webHook");
        console.log("data", data)

        setresponse(data)

        
    } catch (error) {

        
    }

}
    
  return (
    <div>
        <h1>noidoieiode</h1>
        <h1>{response?.name}</h1>

    </div>
  )
}

export default Responce


// use less