import React, { useState, useEffect, createContext } from "react";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../config";
import Navbar from "../../components/navbar/Navbar";

const AddUser = () => {
  const navigate = useNavigate();
 const {AppID} = useParams();

  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [ContactNumber, setContactNumber] = useState();



  const createUser= async (e) => {
    e.preventDefault();
    try {
      if (
        !Name ||
        !Password ||
        !Email ||
        !Password 
      ) {
        return alert("Please fill all fields");
      }
      
      const {data:{
        data:appData
      }} = await axiosInstance.post("/api/app/getAppByAppId",{
        AppID:AppID
      });

      const { data } = await axiosInstance.post(`/api/user`, {
        AppId:AppID,
        Password,
        Email,
        Name,
        ContactNumber,
        App: appData._id,
        isCompany:true
      });
      if(data.success){
        navigate(`/admin/subscribe/${AppID}`)
        alert(data.message);
      }
      console.log(data, "create data");
    } catch (error) {
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont">
        {/* <!-- Top Nav --> */}
        <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true}/>
        {/* <!--End Top Nav --> */}
      
        < hr className='hrTag'/>
        <div className="row mx-2 ">
          <div className="toogler">
            <h3 className="text-white">CREATE CLIENT ( SECOND STEP ) </h3>
          </div>

          <form
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}
            onSubmit={createUser}
          >
            <div className="col-md-8 align-items-center">
              <label  className="text-white">CLIENT NAME</label>
              <input
                className="form-control mt-3 mb-3"
                type="text"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                placeholder="CLIENT NAME"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white">CLIENT EMAIL</label>
              <input
                className="form-control mt-3 mb-3"
                type="text"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=" CLIENT "
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white">CLIENT CONTACT</label>
              <input
                className="form-control mt-3 mb-3"
                type="text"
                value={ContactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                placeholder=" Content Number "
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white">APP ID</label>
              <input
                className="form-control mt-3 mb-3 "
                type="text"
                value={AppID}
                // onChange={(e) => setAppId(e.target.value)}
                placeholder="CLIENT APP ID "
                required 
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label  className="text-white">Password</label>
              <input
                className="form-control mt-3 mb-3 "
                type="text"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="CLIENT PASSWORD"
                required
              />
            </div>
            <div className="col-md-8" style={{ padding: "5px" }}>
              <div className="d-flex flex-row">
                <button className="btn btn-success">Create User</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUser;
