import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: 'user',
    initialState:{
        user:{},
        loading:false,
        error:false,
        errormessage:""
    },
    reducers:{
        getuser:(state,action)=>{
            state.loading = true;
            state.error = false
        },
        userdatasuccess:(state,action)=>{
           
            state.loading = false;
            state.user = action.payload
        },
        userdatafailure:(state,action)=>{
            state.loading = false;
            state.error = true;
            state.errormessage = action.payload;
        },
        reseterror:(state,action)=>{
            state.user={}
            state.loading=false
            state.error=false
            state.errormessage=""
        },
        logouthandler:(state)=>{
            state.user={}
            state.loading=false
            state.error=false
        }
    }
})


export const {getuser,userdatasuccess,userdatafailure,reseterror,logouthandler} = userSlice.actions;
export default userSlice.reducer;
