import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/home/Home";

//Admin Components

import Responce from "./components/Responce";
// import Login from "./components/login/Login";
import AllUsers from "./admin/users/AllUsers";
import EditUser from "./admin/users/EditUser";
import CreateApplicarion from "./admin/Contest/CreateApplicarion";
import AllApplication from "./admin/Contest/AllApplication";
import AddUser from "./admin/users/AddUser";

import EditApp from "./admin/Contest/EditApp";
import Subscribe from "./admin/Subscribe/Subscribe";
import AllLeads from "./salesPersonDashboard/Leads/AllLeads";

import LoginPage from "./pages/auth/LoginPage";
import ResetPassword from "./pages/auth/ResetPassword";

// SalesPerson
import SalesPerson from "./companyDashboard/salesPerson/SalesPerson";
import AddSalesPerson from "./companyDashboard/salesPerson/AddSalesPerson";
import EditSalesPerson from "./companyDashboard/salesPerson/EditSalesPerson";

//Service
import Servicedashboard from "./companyDashboard/services/Servicedashboard";
import AddService from "./companyDashboard/services/AddService";
import EditService from "./companyDashboard/services/EditService";
import { useDispatch, useSelector } from "react-redux";
import { getuser, reseterror, userdatasuccess } from "./redux/slice/userSlice";
import {
  getsalesPerson,
  salesPersondatasuccess,
} from "./redux/slice/salespersonSlice";
import { axiosInstance } from "./config";
import ClientProfile from "./companyDashboard/client/ClientProfile";
import Leads from "./companyDashboard/Leads/Leads";
import Dashboard from "./companyDashboard/dashboard/Dashboard";
import AssignLeads from "./companyDashboard/AssignLeads/AssignLeads";
import AssignServices from "./companyDashboard/AddServices/AssignServices";
import SalesPersonProfile from "./salesPersonDashboard/profile/SalesPersonProfile";
import AdminDashBoard from "./companyDashboard/AdminDashboard/AdminDashBoard";

function App() {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { salesPerson } = useSelector((state) => state.salesPerson);

  console.log("salesPerson", salesPerson,user?.isCompany);

  useEffect(() => {
    if (localStorage.getItem("tokenone")) {
      getuserdata();
    }
    if (localStorage.getItem("tokentwo")) {
      getsalesPersondata();
    }
  }, []);

  const getuserdata = async () => {
    console.log("Admin");
    try {
      dispatch(getuser());
      const { data } = await axiosInstance.get("/api/user/accesstoken/me", {
        headers: {
          token: localStorage.getItem("tokenone"),
        },
      });
      if (data.success) {
        console.log("data", data.data);
        localStorage.removeItem("tokentwo");
        dispatch(userdatasuccess(data.data));
      }
    } catch (error) {
      console.log("eeee", error);
      localStorage.clear()
      dispatch(reseterror());
    }
  };

  const getsalesPersondata = async () => {
    console.log("SalesPerson");
    try {
      // /api/salesPerson

      dispatch(getsalesPerson());

      const { data } = await axiosInstance.get(
        "/api/salesPerson/accesstoken/me",
        {
          headers: {
            token: localStorage.getItem("tokentwo"),
          },
        }
      );
      if (data.success) {
        console.log("data", data.data);
        dispatch(salesPersondatasuccess(data.data));
      }
    } catch (error) {
      console.log("eeee", error);
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {user?.isCompany && user.isCompany ? (
          <>
            <Route path="/" element={<Dashboard />} />
          </>
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
          </>
        )}

        {JSON.stringify(salesPerson) !== "{}" && salesPerson ? (
          <>
            <Route path="/salesPerson/viewLeads" element={<AllLeads />} />
            <Route path="/salesPerson/Profile" element={<SalesPersonProfile />} />
          </>
        ) : (
          <>
            <Route path="/" element={<LoginPage />} />
          </>
        )}

        <Route path="/home" element={<Home />} />
        <Route path="/response" element={<Responce />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        {/* admin Routes*/}
        {true && (
          <>
            {" "}
            <Route path="/admin/user" element={<AllUsers />} />
            <Route path="/admin/AddUser/:AppID" element={<AddUser />} />
            <Route path="/admin/user/:id" element={<EditUser />} />
            <Route path="/admin/app" element={<AllApplication />} />
            <Route path="/admin/CreateApp" element={<CreateApplicarion />} />
            <Route path="/admin/editApp/:id" element={<EditApp />} />
            <Route path="/admin/subscribe/:AppID" element={<Subscribe />} />
            <Route path="/admin/AdminDashBoard" element={<AdminDashBoard />} />
          </>
        )}
        {/* admin Routes */}

        {/* Client  Routes*/}
        {user?.isCompany && (
          <>
            <Route path="/client/salesperson" element={<SalesPerson />} />
            <Route path="/client/addsalesperson" element={<AddSalesPerson />} />
            <Route path="/client/leads" element={<Leads />} />
            <Route
              path="/client/editsalesperson/:id"
              element={<EditSalesPerson />}
            />
            <Route path="/client/services" element={<Servicedashboard />} />
            <Route path="/client/addservice" element={<AddService />} />
            <Route path="/client/editservice/:id" element={<EditService />} />
            <Route path="/client/profile" element={<ClientProfile />} />
            <Route path="client/dashboard" element={<Dashboard />} />
            <Route path="/client/assign/leads" element={<AssignLeads />} />
            <Route path="/client/AddServices" element={<AssignServices />} />
          </>
        )}

        <Route path="*" element={<LoginPage />} />

        {/*salesPerson Routes */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
