import { createSlice } from "@reduxjs/toolkit";

export const salespersonSlice = createSlice({
    name: 'salesperson',
    initialState:{
        salesPerson:{},
        loading:false,
        error:false,
        errormessage:""
    },
    reducers:{
        getsalesPerson:(state,action)=>{
            state.loading = true;
            state.error = false
        },
        salesPersondatasuccess:(state,action)=>{
            state.loading = false;
            state.salesPerson = action.payload
        },
        salesPersonfailure:(state,action)=>{
            state.loading = false;
            state.error = true;
            state.errormessage = action.payload;
        },
        reseterror:(state,action)=>{
            state.salesPerson={}
            state.loading=false
            state.error=false
            state.errormessage=""
        },
        logouthandler:(state,action)=>{
            state.salesPerson={}
            state.loading=false
            state.error=false
            state.errormessage=""
        }
    }
})


export const {getsalesPerson,salesPersondatasuccess,salesPersonfailure,reseterror,logouthandler} = salespersonSlice.actions;
export default salespersonSlice.reducer;
