import React, { useState, useEffect } from "react";
// import { axiosInstance } from "../../../config";
import { Link } from "react-router-dom";
import { AiFillDelete } from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import Sidebar from "../sidebar/Sidebar";
import { axiosInstance } from "../../config";
import Navbar from "../../components/navbar/Navbar";

const AllUsers = () => {
  const [User, setUser] = useState();
  
  const [del, setDel] = useState(false);

  useEffect(() => {
    getUsers();
  }, [del]);

  const getUsers = async () => {
    try {
      const { data } = await axiosInstance.get("/api/user");
      console.log(data, "users");
      setUser(data.data.reverse());
    } catch (error) {
      console.log("error")
      alert(error.response.data.message);
    }
  };





  const deleteUser = async (id) => {
    var answer = window.confirm("Are you sure To delete?");
    try {
      if (answer) {
        const { data } = await axiosInstance.delete(`/api/user/${id}`);
        if (data.success) {
          alert(data.message);
        }
      }
      setDel(!del);
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  return (
    <>
      <Sidebar />

      <div className="p-1 my-container active-cont overflow-hidden">
        {/* <!-- Top Nav --> */}
        <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true}/>
        {/* <!--End Top Nav --> */}
       
        < hr className='hrTag'/>

        <div className="row ">
          <div className="col-md-9"></div>
          {/* <div className="col-md-3 btn btn-secondary  mx-5"><Link to="/admin/addUser" className="text-white nav-link">ADD NEW CLIENT</Link></div> */}
        </div>

        <div className="row mt-4">
          <div className="col-md-2 text-white"> CLIENT NAME</div>
          <div className="col-md-2 text-white">CLIENT EMAIL </div>
          <div className="col-md-2 text-white">CLIENT APP ID</div>
          <div className="col-md-2 text-white"> AD ACCOUNT ID</div>
          <div className="col-md-2 text-white btn btn-secondary">EXTRA OPTIONS</div>
        </div>

        {!User ? (
          <div
            className="row d-flex justify-content-center position-relative top-50 mt-5 "
            style={{ height: "100vh" }}
          >
            <div className="col-md-8 text center position-absolute top-40 start-50">
              <div class="spinner-border text-white " role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          </div>
        ) : User?.length === 0 ? (
          <>
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-md-8 text-center">
                <h4 className="text-white">No User Available</h4>
              </div>
            </div>
          </>
        ) : (
          User &&
          User.map((c) => (
            <>
              <div className="row " key={c?._id}>
                <div className="col-md-2 text-white">{`${c?.Name} `} </div>
                <div className="col-md-2 text-white">{c?.Email}</div>
                <Link  to={`/admin/subscribe/${c?.AppId}`} className="col-md-2 text-white">{c?.AppId} </Link>
                <div className="col-md-2 text-white">{c?.AdAccountId}</div>
                {/* <div className="col-md-2 text-white">{c.questionType}</div> */}

                <div className="col-md-2 text-white">
                  <div className="row">
                    <div className="col-md-4">
                      <Link className="col-md-1" to={`/admin/user/${c?._id}`}>
                        <BsFillEyeFill size={25} color="blue" />{" "}
                      </Link>
                    </div>
                    <div className="col-md-4">
                      <AiFillDelete
                        color="black"
                        size={25}
                        onClick={() => deleteUser(c?._id)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))
        )}
      </div>
    </>
  );
};

export default AllUsers;
