import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { axiosInstance } from "../../config";
// import { axiosInstance } from "../../../config";
import Sidebar from "../sidebar/Sidebar";





const EditSalesPerson = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [Name, setName] = useState();
  const [Email, setEmail] = useState();
  const [Password, setPassword] = useState();
  const [Number, setNumber] = useState();
  const [serviceName, setserviceName] = useState();

  const [selectedServiceName, setselectedServiceName] = useState();


  useEffect(() => {
    getServices();
    getSalesPerson();
  }, [])


  const getServices = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/services?User=${user._id}`);
      
      setserviceName(data.data);
    } catch (error) {
      console.log("error",error)
    }
  }

  const togglehandle = (e) => {
    e.persist();
    var menu_btn = document.querySelector("#menu-btn");
    var sidebar = document.querySelector("#sidebar");
    var container = document.querySelector(".my-container");
    sidebar.classList.toggle("active-nav");
    container.classList.toggle("active-cont");
  };

  const getSalesPerson = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/salesPerson?_id=${id}`);
      console.log("data",data.data)

      const {Password,Number,Name,Email,Service} = data.data[0]
      setName(Name)
      setEmail(Email)
      setPassword(Password)
      setNumber(Number)
      setselectedServiceName(Service?._id)
      console.log("dd",Service)

    } catch (error) {
      // alert(error.response.data.message);


    }
  };

  const updateSalesPerson = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axiosInstance.put(`/api/salesPerson/${id}`, {
        Password,
        Email:Email,
        Name:Name,
        Number:9119232545,
        Service:selectedServiceName,
      });
      if (data.success) {
        navigate("/client/salesperson")
        alert(data.message);
      }
      // console.log(data, "create data");
    } catch (error) {
      alert(error.response.data.message);
      console.log(error, "error creating data");
    }
  };

 
  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar  name={"Edit Sales Person"}/>
        <hr className='hrTag' />
        {/* <h3 className="text-dark p-3"> ADMIN DASHBOARD 💻 📱</h3> */}
        <div className="row mx-2 ">
          <div className="toogler">
        
          </div>
          <form>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Name</label>
              <input
                className="form-control"
                type="text"
                value={Name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Sales person Name"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Email</label>
              <input
                className="form-control "
                type="text"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter Sales person Email"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Number</label>
              <input
                className="form-control "
                type="text"
                value={Number}
                onChange={(e) => setNumber(e.target.value)}
                placeholder="Enter Sales Person Number"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Password</label>
              <input
                className="form-control "
                type="text"
                value={Password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter Sales Person Password"
                required
              />
            </div>
            <div className="col-md-8 align-items-center">
              <label className="text-white">Service Name</label>
              <select class="form-select" aria-label="Default select example" value={selectedServiceName} onChange={(e) => setselectedServiceName(e.target.value)}>
                <option selected> Select Service Name</option>
                {
                  serviceName && serviceName.map((service) => (
                    <>
                      <option value={service?._id}>{service?.serviceName}</option>

                    </>
                  ))
                }
              </select>
            </div>
          </form>
          <div className="col-md-8" style={{ padding: "5px" }}>
            <div className="d-flex flex-row">
              <button className="btn btn-success" onClick={updateSalesPerson}>
                Update User
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSalesPerson;
