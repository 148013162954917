import React, { useState } from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { axiosInstance } from "../../config";
import Sidebar from "../sidebar/Sidebar";
import "./subscribe.css";
const Subscribe = () => {
  
  const {AppID} = useParams();
  const [facebookPageData, setFacebookPageData] = useState();
  const [Application, setApplication] = useState();
  
  const loginWithFaceBook = async () => {
  
    window.FB.init({
      appId: AppID,
      autoLogAppEvents: true,
      xfbml: true,
      version: "v15.0",
    });
    window.FB.login((authResponse) => {
      console.log(authResponse, "login with fb");
      window.FB.api("me/accounts", (res) => {
        setFacebookPageData(res.data);
        console.log(res, "api me account");
      });
    });
  };
  //   console.log(AppId)
  const getAppByAppId = async () => {
    try {
      const { data } = await axiosInstance.post("/api/app/getAppByAppId", {
        AppID:AppID,
      });
      setApplication(data.data);
      console.log(data, "getAppByAppId");
    } catch (error) {
      console.log(error, "getAppByAppId");
    }
  };

  const UnsubscribePageInApp = async (pageId, access_token) => {
    try {
      const { data } = await axiosInstance.post(
        `/api/app/UnsubscribePageInApp`,
        {
          AppID: AppID,
          pageId,
          access_token,
        }
      );
      if (data?.success) {
        getAppByAppId();
        alert(data?.message);
      }
      console.log(data, UnsubscribePageInApp);
    } catch (error) {
      console.log(error, "UnsubscribePageInApp");
    }
  };
  const subscribePageInApp = async (pageId, access_token) => {
    try {
      const { data } = await axiosInstance.post(`/api/app/subscribePageInApp`, {
        AppID: AppID,
        pageId,
        access_token,
      });
      if (data?.success) {
        getAppByAppId();
        alert(data?.message);
      }
      console.log(data, "subscribePageInApp");
    } catch (error) {
      console.log(error, "subscribePageInApp");
    }
  };
  useEffect(() => {
    getAppByAppId();
  }, []);
  return (
    <>
      <Sidebar />
      <div className="p-1 my-container active-cont">
      <Navbar name={"DIGI SIDEKICK DASHBOARD "} isAdmin={true}/>
        <hr className="hrTag" />
        <div className="row mx-2 ">
          <div className="toogler">
            <h3 className="text-white">Subscribe Pages</h3>
          </div>
        </div>
        
     
        <div className="row mx-2 ">
          <div className="mt-3 mb-3">
            <Button
              onClick={loginWithFaceBook}
              variant="primary"
              className="m-1  text-center"
            >
              LOGIN TO FACEBOOK
            </Button>
            <Button
              onClick={getAppByAppId}
              variant="primary"
              className="m-1  text-center"
            >
              CHECK SUBSCRIPTIONS
            </Button>
          </div>
        </div>

        <div className="row mx-2 ">
          <div >
            <ui>
              {facebookPageData &&
                facebookPageData.map((data) => (
                  <li className="Pages">
                    <>
                      <p className="text-white">{data.name}</p>
                      {!Application?.Pages.includes(String(data?.id)) ? (
                        <button
                          onClick={() =>
                            subscribePageInApp(data?.id, data?.access_token)
                          }
                          type="button"
                          class="btn btn-success m-3"
                        >
                          Subscribe
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            UnsubscribePageInApp(data?.id, data?.access_token)
                          }
                          type="button"
                          class="btn btn-danger m-3"
                        >
                          Unsubscribe
                        </button>
                      )}
                    </>
                  </li>
                ))}
            </ui>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
